import ReactGA from 'react-ga4';
import { ProjectFiltersList } from '~/constants';

export default function sendFilterClickEvent(filter: string) {
   const projectFilter = ProjectFiltersList.find(f => f.value === filter);
   if (projectFilter) {
      ReactGA.event({
         category: 'click',
         action: 'Filter Click - ' + projectFilter.label
      });
   }
}
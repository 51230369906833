import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { sendNotifications } from '~/api/admin';
import { Button, Container } from '~/components';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

export default function QA() {
  useAnalyticsPageView('/qa', 'QA');

  const { mutate: sendUserNotification, status } = useMutation(() => {
    return sendNotifications()
      .then(() => {
        toast.success('Successfully sent notifications', { duration: 4000 });
      }).catch(err => {
        console.error(err);
        toast.error('Error occurred sending notifications. See console logs for more info.', { duration: 6000 });
      });
  });

  return (
    <Container>
      <h1 style={{ marginTop: '4rem' }}>QA Helper Page</h1>
      <p>
        This is a rudementary screen to help with certain QA actions. 
        To start out, it only contains a button for manually sending out notifications.
      </p>
      <Button primary onClick={() => sendUserNotification()} loading={status === 'loading'}>
        Manually send notification emails
      </Button>
    </Container>
  );
}
import { differenceInMilliseconds } from 'date-fns';
import { ReactNode, useEffect, useState } from 'react';
import { ApiEvent } from '~/types/ApiEvent';
import * as S from './styles';
import LoadingStateDots from './LoadingStateDots';

export default function AsyncRequestButton({
  onClick,
  onSuccess = () => {},
  onError = () => {},
  pollFn,
  onTimeout,
  text,
  loadingText = 'Loading',
  apiEvent,
  disabled = false
}: {
  onClick: Function;
  onSuccess?: Function;
  onError?: Function;
  onTimeout?: Function;
  pollFn?: (apiEventId: string) => Promise<ApiEvent>;
  text: string | ReactNode;
  loadingText?: string | ReactNode;
  apiEvent?: ApiEvent;
  disabled?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState<null |'1 minute' | 'less than 1 minute remaining'>(null);
  const [apiEventState, setApiEventState] = useState<ApiEvent | null>(null);

  // Log a warning if pollFn is provided but onTimeout is not provided.
  // This is because after polling a certain number of times with no result,
  // the button will just stop polling and the user will not be notified of the timeout.
  useEffect(() => {
    if (pollFn && !onTimeout) {
      console.warn('AsyncRequestButton WARNING: onTimeout is heavily recommended when pollFn is provided');
    }
  }, [pollFn, onTimeout]);

  useEffect(() => {
    if (apiEvent) {
      setApiEventState(apiEvent);
    } else {
      setApiEventState(null);
    }
  }, [apiEvent]);

  useEffect(() => {
    if (!!pollFn && apiEventState?.status === 'PENDING') {
      // Poll DNV results every 10 seconds.
      // After 9 attempts, back off to every 30 seconds for 3 final attempts.
      let attempts = 0;
      const ATTEMPT_THRESHOLD_INITIAL = 9;
      const ATTEMPT_THRESHOLD_FINAL = 12;
      const POLL_INTERVAL_INITIAL = 10000;
      const POLL_INTERVAL_FINAL = 30000;
      const timeSinceRequest = differenceInMilliseconds(new Date(), new Date(apiEventState.createdAt));

      let currentEstimatedTime = estimatedTime;
      if (!estimatedTime) {
        if (timeSinceRequest > 40000) {
          currentEstimatedTime = 'less than 1 minute remaining';
          setEstimatedTime(currentEstimatedTime);
        } else {
          currentEstimatedTime = '1 minute';
          setEstimatedTime(currentEstimatedTime);
        }
      }

      let estimateUpdater: any;
      let pollTimeout: any;

      const poll = async () => {
        try {
          const pollStatus = await pollFn(apiEventState.id).then((res: ApiEvent) => res.status);

          if (!estimateUpdater && currentEstimatedTime === '1 minute') {
            // Update estimated time to less than 1 minute after 40 seconds have passed from the initial request
            estimateUpdater = setTimeout(() => {
              setEstimatedTime('less than 1 minute remaining');
            }, 40000 - timeSinceRequest);
          }
        
          if (pollStatus === 'SUCCESS') {
            try {
              await onSuccess();
            } finally {
              setLoading(false);
            }
          } else if (pollStatus === 'PENDING') {
            if (attempts < ATTEMPT_THRESHOLD_INITIAL) {
              pollTimeout = setTimeout(poll, POLL_INTERVAL_INITIAL);
            } else if (attempts < ATTEMPT_THRESHOLD_FINAL) {
              pollTimeout = setTimeout(poll, POLL_INTERVAL_FINAL);
            } else {
              onTimeout?.();
              setLoading(false);
            }
            attempts++;
          } else {
            throw new Error('Unknown ApiEvent status: ' + pollStatus);
          }
        } catch (err: any) {
          console.error('Error polling for Async API Event ' + JSON.stringify(apiEventState), err);
          onError?.(err.response?.data ?? {});
          setLoading(false);
          return;
        }
      };

      // Kick off the initial poll
      setLoading(true);
      poll();

      // Cleanup on unmount
      // (On the surface this might not look important. It is. Do not remove this cleanup function, as it will cause memory leaks)
      return () => {
        if (pollTimeout) {
          clearTimeout(pollTimeout);
        }
        if (estimateUpdater) {
          clearTimeout(estimateUpdater);
        }
      };
    }
  }, [apiEventState]);

  const handleClick = async () => {
    setLoading(true);
    try {
      if (!pollFn) {
        setEstimatedTime('1 minute');
        setTimeout(() => {
          setEstimatedTime('less than 1 minute remaining');
        }, 5000);
      }
      const result = await onClick();
      if (!result) {
        // returned undefined, meaning the parent aborted the request
        setLoading(false);
      }
      if (result?.status === 'PENDING') {
        setApiEventState(result);
        setEstimatedTime('1 minute');
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
    
  };

  return (
    <div>
      <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', position: 'relative' }}>
        <S.Button
          primary 
          outlined 
          disabled={disabled || loading}
          onClick={handleClick}
          className={loading ? 'asyncButtonLoading' : ''}
          style={{ 
            cursor: 'pointer',
            display: 'flex', 
            alignItems: 'center', 
            position: 'relative', 
            paddingLeft: loading ? 'var(--x-large)' : '1rem',
            transition: 'padding-left 0.25s'
          }}
        >
          <S.Loader style={{ opacity: Number(loading) }} />
          {loading 
            ? <span>{loadingText}<LoadingStateDots /></span>
            : text
          }
        </S.Button>
        {loading && (
          <p style={{ fontStyle: 'italic', marginTop: '0.5rem', position: 'absolute', bottom: '-1.5rem' }}>
            Estimated time: {estimatedTime}
          </p>
        )}
      </div>
    </div>
  );
}
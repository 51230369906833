import { TutorialCodes } from '~/constants';
import TutorialModal from '../../TutorialModal';

export default function InvEarlyDataroomWelcome() {

  return (
    <TutorialModal
      title='Early access documents'
      content={
        <>
          <p>
            Your developer partner has granted you early access to their documents, and everyone in your organization has permission to view and download any documents available here. 
          </p>
          <p>
            Within the documents area you will see uploads categorized using the left navigation element to help you quickly reach relevant information, and you’ll get notified as new documents are added.
          </p>
        </>
      }
      hasCancelButton={false}
      submitButtonText='OK'
      tutorialCode={TutorialCodes.INVESTOR_EARLY_DATAROOM_WELCOME}
    />  
  );
};  
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Checkbox, Dropdown, Loader } from 'semantic-ui-react';
import { addPortfolioProjects } from '~/api/portfolioProjects';
import { usePortfolioContext } from '~/hooks';
import { useProjectTeamQuery } from '~/requests/team';
import { withPromiseToaster } from '~/utils';
import { Project } from '~/types/project';

interface AddOrRemoveProjectCheckboxProps {
  project: Project;
  selected?: boolean;
  setProjectToRemove: (project: Project) => void;
}

export default function AddOrRemoveProjectCheckbox({ 
  project, 
  selected: defaultSelected = false,
  setProjectToRemove
}: AddOrRemoveProjectCheckboxProps) {
  const label = project.name;
  const projectId = project.id;
  const { id: portfolioId, updatePortfolioValue } = usePortfolioContext();
  const { refetch: refetchPortfolioTeam } = useProjectTeamQuery();
  const [selected, setSelected] = useState(defaultSelected);

  const { mutate: addProject, status: addProjectStatus } = useMutation(
    async () => {
      await withPromiseToaster(
        addPortfolioProjects(portfolioId, { projectIds: [projectId] })
          .then((projects) => {
            // If there's no error, update the portfolio in the cache
            updatePortfolioValue('projects', projects, { doSave: false });

            // Trigger a refetch of portfolio teams
            refetchPortfolioTeam();
          })
          .catch((err: any) => {
            // If there's an error, unselect the checkbox and throw the error
            console.error(err);
            setSelected(false);
            throw err;
          }),
        {
          messageStub: 'adding project to your portfolio'
        }
      );
    }
  );

  // The checkbox just prevents default behavior and doesn't actually change the state.
  // It just propagates click events to the Dropdown.Item component which handles the click.
  const checkboxHandler = (e: any) => e.preventDefault();

  // The dropdown handler is what handles selecting/unselecting the project
  const dropdownHandler = (e: any) => {
    if (selected) {
      setProjectToRemove(project);
    } else {
      setSelected(!selected);
      addProject();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const isLoading = addProjectStatus === 'loading'; 

  return (
    <>
      <Dropdown.Item
        selected={selected}
        onClick={dropdownHandler}
        disabled={isLoading}
      >
        <Checkbox 
          checked={selected}
          onChange={checkboxHandler}
          onClick={checkboxHandler}
          onMouseDown={checkboxHandler}
          onMouseUp={checkboxHandler}
        />
        <div>{label}</div>
        <div style={{ marginLeft: 'auto', position: 'relative', paddingRight: isLoading ? 'var(--small)' : 0 }}>
          {isLoading && <Loader size="tiny" active />}
        </div>
      </Dropdown.Item>
    </>
  );
}
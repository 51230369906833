import { TutorialCodes } from '~/constants';
import TutorialModal from '../../TutorialModal';

export default function InvDataroomSetup() {

  return (
    <TutorialModal
      title='Setting up your documents'
      content={
        <>
          <p>
            The documents area is where you and your partner will manage project documentation throughout the diligence process. You have the option to retain any documents that may have been previously shared by your partner, or you can completely customize your required documents to your specific needs. 
          </p>
          <p>
            We’ve started you off with a list of recommended documents based on the project details, and you can click ‘Edit doc list’ to add and remove any document containers you like. As your partner uploads documents you’ll get notified in-app, and as you review and approve them, Conductor automatically keeps track of the count of outstanding documents so you can quickly focus your attention where it’s needed. 
          </p>
        </>
      }
      hasCancelButton={false}
      submitButtonText='OK'
      tutorialCode={TutorialCodes.DATA_ROOM_SETUP}
    />  
  );
};  
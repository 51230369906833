export const Unit: { [key: string]: 'WDC' | 'TOTAL' | 'YEAR' | 'KWH' | 'PERCENT' } = {
  WDC: 'WDC',
  TOTAL: 'TOTAL',
  YEAR: 'YEAR',
  KWH: 'KWH',
  PERCENT: 'PERCENT',
};

export const UnitDecimals: { [key: string]: number } = {
  WDC: 3,
  KWDC: 4,
  KWAC: 4,
  DOLLARS_PER_MWH: 2,
  TOTAL: 0,
  YEAR: 0,
  YEAR_ONE_PAYMENT: 0,
  KWH: 4,
  PERCENT: 2,
  DOLLARS: 2
};

export const UnitDisplay = {
  WDC: 'Wdc',
  TOTAL: 'Total',
  YEAR: 'Year',
  KWH: 'kWh',
};

export const AutopricingUnit = {
  WDC: 'WDC',
  KWH: 'KWH',
  TOTAL: 'TOTAL',
  YEAR_ONE_PAYMENT: 'YEAR_ONE_PAYMENT',
};

// This is the only place where the display text should be changed as this data is tied to the autopricing spreadsheet
export const AutopricingUnitDisplay  = {
  WDC: '$ per Wdc',
  KWH: '$ per kWh',
  TOTAL: 'EPC cost + dev fee',
  YEAR_ONE_PAYMENT: 'Expected year 1 payment',
};

export type AutopricingUnitDisplayKeys = keyof typeof AutopricingUnitDisplay;


export const ProductionUnit = {
  KWH: 'KWH',
  KWHKW: 'KWH_KW',
};

export const ProductionUnitDisplay = {
  KWH: 'kWh',
  KWHKW: 'kWh per kW',
};

export const ExpensesUnit = {
  KWDC: 'KWDC',
  YEAR: 'YEAR',
};

export const OMPriceDisplay = {
  KWDC: '($/kW-year)',
  YEAR: '($/year)',
} as any;

export const RevenueUnit = {
  KWH: 'KWH',
  YEAR: 'YEAR',
};

export const TaxUnit = {
  KWDC: 'KWDC',
  KWAC: 'KWAC',
  YEAR: 'YEAR',
};

export const TaxUnitDisplay = {
  KWDC: 'kWdc',
  KWAC: 'kWac',
  YEAR: 'year',
};
import { useEffect, useRef, useState } from 'react';
import { Card, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import PageContainerGrid from '~/components/PageContainerGrid';
import useHeightCalculation from '~/hooks/useHeightCalculation';
import useScrollState from '~/hooks/useScrollState';
import Link from '~/components/Link';
import sendClickEvent from '~/utils/analytics/sendClickEvent';

interface FooterProps {
  onHeightCalculation: (height: number) => void;
}

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  left: var(--sidebar-width);
  right: 0;
  background: var(--color-off-white);
  width: calc(100vw - var(--sidebar-width));
  padding: var(--large) 0 var(--medium) 0;
  transition: 0.3s ease-in-out;
  padding-left: 0;
  padding-right: 0;
  box-shadow: var(--color-off-white) 0px -0.75rem var(--medium);
  padding-top: 1rem;
  .ui.grid.container {
    padding: 0;
    > .row:first-child {
      padding-bottom: 1rem;
    }
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  > .ui.card {
    width: 100%;
    margin: 0;
  }
  > .ui.card + .ui.card {
    margin-left: var(--medium);
  }
`;

const FooterInfo = styled.div`
  * {
    margin: 0;
    padding: 0;
  }
  
  line-height: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--large);
  margin-top: var(--medium);
`;

const FooterCopyright = styled.p`
  color: var(--color-secondary);
  font-size: 0.875rem;
  font-weight: 400;
`;

// TODO get real blog data for this section (this is ok to commit for now, but needs finalized for release)
const blogData = [
  {
    title: 'How to Use Conductor',
    date: 'December 14, 2023',
    description: 'Conductor Solar is a marketplace where developers and EPCs find the best financiers for commercial and community solar projects. We match projects and portfolios with investors by ...',
    href: 'https://conductor.solar/blog/how-to-use-conductor'
  },
  {
    title: 'Introducing ITC Transfers',
    date: 'December 14, 2023',
    description: 'Conductor Solar now supports ITC Transfers for C&I and community solar projects! Building on the success of our marketplace for PPA and lease financing, we\'ve created a marketplace ...',
    href: 'https://conductor.solar/blog/introducing-itc-transfers'
  },
  {
    title: 'Teams and Notifications',
    date: 'May 13, 2024',
    description: 'We’ve just released an update that gives everyone new flexibility to see the information that they want to see, assemble internal teams for each project, and invite collaborators from ...',
    href: 'https://conductor.solar/blog/choose-your-own-adventure-on-conductor'
  }
];

export default function Footer({ onHeightCalculation }: FooterProps) {
  const ref = useRef(null);
  const height = (ref.current as any)?.clientHeight;
  const [showing, setShowing] = useState(true);
  const scrollState = useScrollState();

  // Determine whether footer shows based on scrollState
  // Footer shows if:
  // 1. Document is not scrollable (e.g. there is no scrollbar)
  // 2. Scroll direction is up
  // 3. Scroll is at the top
  // 4. Scroll is not at the bottom
  useEffect(() => {
    const { isDocumentScrollable, scrollDirection, isScrolledToTop, isScrolledToBottom } = scrollState;
    setShowing(!isDocumentScrollable || ((isScrolledToTop || scrollDirection !== 'down') && !isScrolledToBottom));
  }, [scrollState]);

  // Report the height of this component to the parent
  useHeightCalculation(ref, onHeightCalculation);

  return (
    <StyledFooter ref={ref} style={{ transform: showing ? 'translate3d(0, 0, 0)' : `translate3d(0, ${height}px, 0)`, opacity: showing ? 1 : 0 }}>
      <PageContainerGrid>
        <Grid.Row>
          <Grid.Column>
            <FlexContainer>
              {blogData.map(({ title, date, description, href }, i) => (
                <Card as="a" target="_blank" href={href} key={`dashboard-footer-blog-post-${i}`}>
                  <Card.Content>
                    <Card.Header style={{marginBottom: 'var(--x-small)'}}>
                      {title}
                    </Card.Header>
                    <Card.Description >
                      {description}
                    </Card.Description>
                  </Card.Content>
                </Card>
              ))}
            </FlexContainer>
          </Grid.Column>
        </Grid.Row>
      </PageContainerGrid>
      <FooterInfo>
        <Link
          as='a'
          href='https://conductor.solar/terms-of-service'
          linkText='Terms of Service'
          fontSize='0.875rem'
          underline
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => sendClickEvent('Terms of Service')}
        />
        <Link
          as='a'
          href='https://conductor.solar/privacy-policy'
          linkText='Privacy Policy'
          fontSize='0.875rem'
          underline
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => sendClickEvent('Privacy Policy')}
        />
        <FooterCopyright>© Conductor Solar, Inc.</FooterCopyright>
      </FooterInfo>
    </StyledFooter>
  );
}
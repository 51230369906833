import * as S from '../../../../components/GridComponent';
import styled from 'styled-components';
import logo from '../../../../assets/conductor-avatar-transparent.png';
import { AutopricingUnitDisplay, AutopricingUnitDisplayKeys, UnitDecimals } from '~/constants';
import { AutopricingResultDataItem, PricingTypes, Project } from '~/types/project';
import { formatDollars } from '~/utils';

const EstimateHeader = styled.p`
  color: var(--color-black);
  font-weight: 700;
  font-style: italic;
  margin: 0;

  flex-shrink: 0;
  align-self: center;
`;

export default function PricingEstimate({ project }: { project: Project }) {
  const hasPricingEstimate = Boolean(project.autopricingResult?.[project.pricingType as PricingTypes]?.length);
  const pricingType = project?.pricingType;

  const autopricingResult = project?.autopricingResult ? project?.autopricingResult[pricingType as PricingTypes]: [];

  return (
    <S.SubGrid columnValues='10rem 1fr'>
      <div style={{ display: 'flex', gap: 'var(--2x-small)'}}>
        <img src={logo} style={{ width: '3.5rem', height: '3.5rem' }} alt='' />
        <EstimateHeader>Conductor Solar estimated project pricing</EstimateHeader>
      </div>
      <div className='line'></div>
      {hasPricingEstimate ? (
        <>
          {autopricingResult?.map((row: AutopricingResultDataItem) => (
            <S.GridTable gridColumn='1 / 3' columnValues='1fr 1fr' key={row.unit} lineHeight="1.5rem">
              <S.GridLabelEl black>
                {AutopricingUnitDisplay[row.unit as AutopricingUnitDisplayKeys]}
              </S.GridLabelEl>
              <S.GridTableEl textAlign='right' bold>
                {formatDollars(row.low, { dec: UnitDecimals[row.unit], default0: null })} - {formatDollars(row.high, { dec: UnitDecimals[row.unit], default0: null })}
              </S.GridTableEl>
            </S.GridTable>
          )).reverse()}
        </>
      ) : (
        //empty state
        <S.GridTable gridColumn='1 / 3' columnValues='1fr 1fr' lineHeight="1.5rem">
          <S.GridLabelEl black>
            {AutopricingUnitDisplay.TOTAL}
          </S.GridLabelEl> 
          <S.GridTableEl textAlign='right' bold style={{ fontStyle: 'italic'}}>
            Awaiting estimate
          </S.GridTableEl>
          <S.GridLabelEl bold>
            $ per unit
          </S.GridLabelEl> 
          <S.GridTableEl textAlign='right' bold style={{ fontStyle: 'italic'}}>
            Awaiting estimate
          </S.GridTableEl>
        </S.GridTable>
      )}
  </S.SubGrid>
  );
};
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { archivePortfolio, reactivatePortfolio, unArchivePortfolio } from '~/api/portfolios';
import { archiveProject, reactivateProject, unArchiveProject } from '~/api/projects';
import { usePageType, usePortfolioContext, useProjectContext } from '~/hooks';
import { permissionsKey } from '~/requests/permissions/usePermissions';
import { requiredResponseQueryKey } from '~/requests/projects/useRequiredResponseCounts';
import sendPortfolioClickEvent from '~/utils/analytics/sendPortfolioClickEvent';
import sendProjectClickEvent from '~/utils/analytics/sendProjectClickEvent';

export default function useToggleArchiveMutation(action: 'archive' | 'unarchive' | 'reactivate') {
  const history = useHistory();
  const pageType = usePageType();
  const queryClient = useQueryClient();
  const { portfolio } = usePortfolioContext();
  const { project } = useProjectContext();
  const id = portfolio?.id ?? project?.id;

  const archive = pageType === 'portfolio' ? archivePortfolio : archiveProject;
  const unArchive = pageType === 'portfolio' ? unArchivePortfolio : unArchiveProject;
  const reactivate = pageType === 'portfolio' ? reactivatePortfolio : reactivateProject;
  
  return useMutation(
    async () => {
      try {
        if (action === 'reactivate') {
          const response = await reactivate(id);
          // Redirect to reactivated project or portfolio
          history.push(`/${pageType}/${response.id}`);

          // Invalidate permissions query for the archived project or portfolio
          queryClient.invalidateQueries(permissionsKey(pageType, id));
        } else if (action === 'unarchive') {
          await unArchive(id);
        } else {
          const { resultCode } = await archive(id);
          if (resultCode === 'ARCHIVE_REQUESTED') {
            toast.success(`A request has been sent to the Conductor Team to archive this ${pageType}.`, { duration: 5000 });
            return;
          } else {
            // Invalidate required response counts query for the archived project or portfolio
            queryClient.invalidateQueries(requiredResponseQueryKey());
          }
        }

        // Invalidate and refetch the relevant queries
        queryClient.invalidateQueries(`${pageType}/${id}`);

        // Analytics
        if (pageType === 'project'){
          sendProjectClickEvent(`Project ${action}d`, project.name, project.id);
        } else if (pageType === 'portfolio'){
          sendPortfolioClickEvent(`Portfolio ${action}d`, portfolio.name, portfolio.id);
        }
        
        toast.success(`The ${pageType} has been successfully ${action}d`, { duration: 5000 });
      } catch (err) {
        toast.error(`Conductor ran into an issue while trying to ${action} the ${pageType}. Please refresh and try again.`, { duration: 5000 });
        console.error(err);
      }
    }
  );
}
import ReactGA from 'react-ga4';

export default function initializeAnalytics() {
  try {
    ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID!);
    // This doesn't disable automatic page views on refreshes and is page-specific, not a global setting
    ReactGA.gtag('config', { send_page_view: false });
  } catch (err) {
    console.error('Failed to initialize Google Analytics', err);
  }
};
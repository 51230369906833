import ReactGA from 'react-ga4';

export default function sendProjectClickEvent(label: string, projectName: string, projectId: string, additionalParams: any = {}) {
   const formattedName = `${projectName} (${projectId})`;   
   
   ReactGA.event('Click - ' + label, {
      event_category: 'Click',
      project_name: formattedName,
      ...additionalParams
   });
}
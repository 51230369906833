import axios from '~/utils/axiosInstance';

export const fetchProjectEvents = (projectId: string, investorId?: string) => {
  return axios.get(`/projects/${projectId}/events?investorId=${investorId}`)
    .then(res => res.data);
};

export const fetchPortfolioProjectEvents = (portfolioId: string, investorId?: string) => {
  return axios.get(`/portfolios/${portfolioId}/events?investorId=${investorId}`)
    .then(res => res.data);
};
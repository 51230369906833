import { ReactNode } from 'react';
import { ContractType, InvestorBidStatus, PricingType, ProjectStatus, UnitDecimals } from '~/constants';
import { sizeKwdc } from '~/utils/calculators';
import { getProjectBidOverallAmount, normalizeToPerWdc } from '~/utils/calculators/costs';
import { formatDollars } from '~/utils/formatNumber';
import { Project } from '~/types/project';

const getBidAmount = (project: any, bid: any) => {
  // Handle ITC projects
  if (project?.pricingType === PricingType.ITC) {
    return `${bid.amount}% / ${formatDollars(getProjectBidOverallAmount(project, bid, false), { dec: 0 })}`;
  }

  // Handle non-ITC projects
  const decimals = project.pricingType === PricingType.OFFTAKE && project?.contractType === ContractType.PPA ? 4 : 0;
  const overallAmount = getProjectBidOverallAmount(project, bid, false);
  let displayAmount = formatDollars(overallAmount, { dec: decimals });
  if (project?.pricingType === PricingType.OFFTAKE) {
    const isLeaseOrESA = project.contractType === ContractType.LEASE || project.contractType === ContractType.ENERGY_SERVICE_AGREEMENT;
    displayAmount += isLeaseOrESA ? ' per year' : ' per kWh';
  }

  // Tack on $ per Wdc for EPC + Dev fee projects
  if (project?.pricingType === PricingType.ACQUISITION && overallAmount) {
    const dollarsPerWdc = normalizeToPerWdc(sizeKwdc(project), { amount: overallAmount });
    displayAmount += ` / ${formatDollars(dollarsPerWdc, { dec: UnitDecimals.WDC })} per Wdc`;
  }

  return displayAmount;
};

export default function projectBidAmounts(project: Project): { color?: string, text: ReactNode }[] {
  return project?.bidSummary?.map((row: any) => {
    const { bid, projectStatus } = row;
    const metadata = { investorId: row?.investor?.id, projectId: row?.childProjectId };

    if (projectStatus === ProjectStatus.DECLINED_TO_BID) {
      return { color: 'var(--color-red)', text: 'Declined to Bid', ...metadata };
    }

    if (projectStatus === ProjectStatus.BID_REJECTED ||
      bid?.bidStatus === InvestorBidStatus.DECLINED || 
      bid?.bidStatus === InvestorBidStatus.REJECTED) {
      return { color: 'var(--color-red)', text: 'Conversation Ended', ...metadata };
    }
    
    if (!bid || bid?.bidStatus === InvestorBidStatus.INVITED) {
      return { color: 'var(--color-charcoal)', text: 'Awaiting Bid', ...metadata };
    }

    if (bid.bidStatus === InvestorBidStatus.PENDING) {
      return { color: 'var(--color-pure-black)', text: getBidAmount(project, bid), ...metadata, pendingBid: true };
    }

    if (bid.bidStatus === InvestorBidStatus.ACCEPTED) {
      return {
        color: 'var(--color-primary)',
        text: <>{getBidAmount(project, bid)}<br />BID ACCEPTED</>,
        ...metadata
      };
    }

    // Default
    return { color: 'var(--color-charcoal)', text: 'Awaiting Bid', ...metadata };
  });
}
import { PricingType, Unit } from '~/constants';
import { Project } from '~/types/project';

export default function bidUnit(project: Project) {
  const unit = project?.pendingBid?.unit;
  if (!unit || project?.pricingType === PricingType.ACQUISITION) {
    return;
  }

  switch(unit) {
    case Unit.KWH:
      return '$/kWh';
    case Unit.TOTAL:
      return 'Total $';
    case Unit.WDC:
      return '$/Wdc';
    case Unit.YEAR:
      return '$/Year';
  }
}
import PageContainerGrid from '~/components/PageContainerGrid';
import NavRow from './components/NavRow';
import { useEffect, useState } from 'react';
import ItemCreationRow from './components/ItemCreationRow';
import Footer from './components/Footer';
import DashboardTable from './components/DashboardTable';
import { useTutorials } from '~/hooks/providers/TutorialProvider';
import { useProjectList } from '~/requests/projects/useProjectList';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

export default function Dashboard() {
  const { setTutorialContext } = useTutorials() as any;
  const { data: projects } = useProjectList();

  const [navHeight, setNavHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [itemCreationRowHeight, setItemCreationRowHeight] = useState(0);
  
  useAnalyticsPageView('/dashboard', 'Dashboard');

  // create a useEffect that runs only when the projects list changes
  useEffect(() => {
    // Pass data to the tutorial context
    setTutorialContext({
      page: 'dashboard',
      data: {
        projects
      }
    });
  }, [projects]); 

  return (
    <PageContainerGrid style={{ paddingTop: 0 }}>
      <NavRow onHeightCalculation={setNavHeight} />
      <ItemCreationRow navHeight={navHeight} onHeightCalculation={setItemCreationRowHeight} />
      <DashboardTable
        navHeight={navHeight}
        itemCreationRowHeight={itemCreationRowHeight}
        footerHeight={footerHeight} />    
      <Footer onHeightCalculation={setFooterHeight} />
    </PageContainerGrid>
  );
}
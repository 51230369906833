import { Loader as _Loader } from 'semantic-ui-react';
import _Button from '../Button';
import styled from 'styled-components';

export const Loader = styled(_Loader)`
  opacity: 0;
  transition: opacity 0.25s;
  opacity: ${props => props.loading ? 1 : 0};
  &.ui.loader {
    left: 0.5rem;
    margin-left: var(--small);
  }
  &.ui.loader::after {
    border-color: var(--color-primary) transparent transparent;
    animation: loader 1.5s linear;
    animation-iteration-count: infinite;
  }
`;
Loader.defaultProps = { size: 'tiny', active: true };

export const Button = styled(_Button)`
  &.ui.disabled.button.asyncButtonLoading, &.ui.button.asyncButtonLoading:disabled{
    opacity: 1 !important;
  }
`;

import { TutorialCodes } from '~/constants';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import TutorialModal from '../../TutorialModal';
import { useHistory } from 'react-router-dom';
import { Project } from '~/types/project';

export default function FirstInvProjectMatch() {
  const { modalProps } = useModalContext();
  const history = useHistory();

  return (
    <TutorialModal
      title='Submitting your first bid'
      content={
        <>
          <p>
            You’ve been matched with your first project! All projects you’ve been matched with will appear here on your dashboard, and they’ll remain there until your bid is selected by the developer, or either you or the developer decide to end the conversation. 
          </p>
          <p>
            Clicking a project row on the dashboard will navigate you to the project summary screen. From there, you can submit your bids and view the high-level project details, and it’s also how you can access the detailed project data and any documents the developer may have uploaded.  
          </p>
          <p>
            Click the button below to navigate to the project summary now.
          </p>
        </>
      }
      hasCancelButton
      cancelText='OK'
      submitButtonText='Go to project summary '
      tutorialCode={TutorialCodes.FIRST_PROJECT_MATCH}
      onSubmit={() => {
        const id = modalProps.projects?.find((p: Project) => p.worksheetValid)?.id;
        history.push(`/project/${id}/review`);
      }}
    />  
  );

};


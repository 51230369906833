import { useTeamMembersQuery, useTeamQuery } from '~/requests/team';
import TeamMembers from './TeamMembers';
import SettingsPageLoader from '../SettingsPageLoader';
import CompanyLogo from './CompanyLogoForm';
import { Message } from 'semantic-ui-react';
import GlobalCompanySettingsForm from './GlobalCompanySettings';
import styled from 'styled-components';
import { Divider } from 'semantic-ui-react';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useFadeInStyles } from '~/hooks';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

const CompanySettingsContainer = styled.div`
  & > * {
    margin-bottom: var(--small);
  }

  & > *:last-child {
    margin-bottom: var(--large); 
  }      
`;

const CompanySettings = () => {
  const { status: teamMembersStatus } = useTeamMembersQuery();
  const { permissions, status: permissionsStatus } = usePermissions();
  const { status: teamStatus } = useTeamQuery();
  const isLoading = permissionsStatus === 'loading' || teamStatus === 'loading' || teamMembersStatus === 'loading';
  const hasError = permissionsStatus === 'error' || teamStatus === 'error' || teamMembersStatus === 'error';
  const fadeInStyles = useFadeInStyles();

  useAnalyticsPageView('/settings/company', 'Company Settings');

  if (isLoading) {
    return <SettingsPageLoader />;
  } else if (hasError) {
    return   (
      <Message error>
        <Message.Header>An error occurred loading this page.</Message.Header>
        <Message.Content>Please refresh the page to try again.</Message.Content>
      </Message>
    );
  } else {
    return (
      <CompanySettingsContainer style={{ paddingBottom: 'var(--large)', ...fadeInStyles }}>
        <CompanyLogo />
        {permissions.canChangeCompanySettings && <GlobalCompanySettingsForm />}
        <Divider style={{ color: 'var(--color-light-gray)' }} />
        <TeamMembers />
      </CompanySettingsContainer>
    );
  }
};

export default CompanySettings;
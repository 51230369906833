import { ReactNode } from 'react';
import Container from '../Container';
import { useFadeInStyles, usePortfolioContext } from '~/hooks';
import { useLocation } from 'react-router-dom';
import PageHeader from '../PageHeader';
import PortfolioSummary from '~/features/portfolioReview/components/PortfolioSummary';
import ManageProjectsDropdown from './ManageProjectsDropdown';
import DownloadExcelButton from './DownloadExcelButton';
import { DealStates } from '~/constants';
import { PageHeaderProvider } from '../PageHeader/PageHeaderProvider';
import { Label } from 'semantic-ui-react';
import PageLoader from '../PageLoader';
import { useRequiredResponseCountsQuery } from '~/requests/projects/useRequiredResponseCounts';
import PortfolioSubmissionButtons from './components/PortfolioSubmissionButtons';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { useProjectTeamQuery } from '~/requests/team';
import AdditionalActions from '../AdditionalActions';
import ArchiveAction from '../AdditionalActions/ArchiveAction';

const PortfolioPageContent = function({ children }: { children: ReactNode }) {
  const { permissions } = usePermissions();
  const { id, portfolio, portfolioState } = usePortfolioContext();
  const { pathname } = useLocation();
  const portfolioEditable = portfolioState?.latestState < DealStates.IN_DILIGENCE;
  const isInvestor = !permissions.isAdmin && permissions.hasPortfolioInvestorAccess;

  const { data: requiredResponseCountsData, isFetching } = useRequiredResponseCountsQuery();
  const requiredResponseCounts = requiredResponseCountsData?.reduce((acc: number, curr: any) => acc + curr.count, 0) ?? 0;

  return (
    <PageHeaderProvider>
        <Container style={useFadeInStyles()}>
          <PageHeader
            portfolio={portfolio}
            content={
              <div style={{ 
                ...(pathname !== `/portfolio/${id}/review` ? { position: 'relative', height: 0, paddingTop: 0, zIndex: -1, opacity: 0 } : {})
              }}>
                <PortfolioSummary />
              </div>
            }
            navTabs={[{
              path: `/portfolio/${id}/review`,
              content: 'Summary'
            }, 
            {
              path: `/portfolio/${id}/messages`,
              content: (
              <div style={{ display: 'flex', alignItems: 'center' }}> 
                <div>Messages</div>
                {isFetching ? null : (
                  pathname !== `/portfolio/${portfolio.id}/messages` && requiredResponseCounts > 0 && ( 
                  <Label circular color='red' style={{marginLeft: 'var(--x-small)'}}>
                    {requiredResponseCounts}
                  </Label>
                ))}
              </div>
              ),
            }, 
            permissions?.canReadDataroom ? {
              path: `/portfolio/${id}/dataroom`, 
              content: 'Documents'
            } : null,
              permissions.canManageProjectTeam && {
              path: `/portfolio/${id}/team`,
              content: 'Team Management',
            },
          ]}
            navContent={
              <div style={{display: 'flex', alignItems: 'center', gap: 'var(--x-small)'}}>
                {portfolioEditable && !isInvestor && <ManageProjectsDropdown />}
                {!portfolio.isArchived && <PortfolioSubmissionButtons />}
                <DownloadExcelButton />
                <AdditionalActions>
                  {permissions.hasArchiveActions && <ArchiveAction />}
                </AdditionalActions>
              </div>
            }
          />
          <div>
            {children}
          </div>
        </Container>
      </PageHeaderProvider>
  );
};

export default function PortfolioPage({ children }: { children: ReactNode }) {
  const { portfolio, fetchStatus } = usePortfolioContext();
  const { status: permissionsStatus } = usePermissions();
  const { status: teamStatus } = useProjectTeamQuery();

  if (fetchStatus === 'loading' || permissionsStatus === 'loading' || teamStatus === 'loading') {
    return <div style={{ width: '100%', position: 'relative', height: '100vh' }}><PageLoader /></div>;
  } else if (portfolio?.id) {
    return (
      <PortfolioPageContent>
        {children}
      </PortfolioPageContent>
    );
  } else {
    return null;
  }
}

import ReactGA from 'react-ga4';

export const ANALYTICS_HEADERS: {
  cid?: string;
  sid?: string;
} = {
  cid: undefined,
  sid: undefined,
};

// Automatically fetch the client/session id from Google Analytics
// NOTE: If this fails it will fail silently and cid/sid will just stay undefined.
// This happens when the user has adblockers or other privacy extensions that block Google Analytics.
// Any requests that are made before this promise resolves will not have the analytics headers set and this is an acceptable tradeoff.
ReactGA.gtag('get', process.env.REACT_APP_GA4_MEASUREMENT_ID, 'client_id', (cid: string) => {
  ANALYTICS_HEADERS.cid = cid;
});
ReactGA.gtag('get', process.env.REACT_APP_GA4_MEASUREMENT_ID, 'session_id', (sid: string) => {
  ANALYTICS_HEADERS.sid = sid;
});
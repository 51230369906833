import Modal from '~/components/Modal';
import { Button } from '~/components';
import styled from 'styled-components';
import { formatDollars, formatNumber } from '~/utils';

const Item = styled.div`
  text-align: left;
`;
const Label = styled.strong`
  color: var(--color-charcoal);
  display: block;
`;
const Value = styled.strong<{ primary?: boolean }>`
  color: var(--color-pure-black);
  ${props => props.primary && 'color: var(--color-primary);'}
  display: block;
`;

export default function ITCBidConfirmationModal({
  closeModal,
  submitBid,
  submitBidStatus,
  projectCosts
}: {
  closeModal: Function;
  submitBid: Function;
  submitBidStatus: string;
  projectCosts: any;
}) {
  const { expectedItc, expectedItcCost, bid } = projectCosts;
  const bidPercent = bid?.amount ?? 0;
  const bidAmount = expectedItcCost * bidPercent / 100;
  const total = (projectCosts?.overall?.nrsFee ?? 0) + bidAmount;

  return (
    <Modal closeModal={submitBidStatus === 'loading' ? undefined : closeModal}>
      <Modal.Title>Confirm your bid</Modal.Title>
      <Modal.Content>
        <p>
          Your bid details are presented below for your review. 
          Please note that the bid you entered is for the ITC only. 
          Developers do not see the Conductor Solar fee, but they 
          are aware that investors pay Conductor a success fee.
        </p>
        <div style={{ 
          display: 'grid', 
          gridTemplateColumns: '1fr auto', 
          rowGap: 'var(--small)', 
          padding: '0 var(--medium)'
        }}>
          <Item>
            <Label>Bid presented to developer</Label>
            <Value primary>{formatNumber(bidPercent, undefined, { minDec: 0, maxDec: 4 })}% / {formatDollars(bidAmount)}</Value>
          </Item>
          <Item>
            <Label>Expected ITC value</Label>
            <Value>{formatDollars(expectedItcCost)}</Value>
          </Item>
          <Item>
            <Label>Conductor fee</Label>
            <Value primary>{formatDollars(projectCosts?.overall?.nrsFee)}</Value>
          </Item>
          <Item>
            <Label>Expected ITC %</Label>
            <Value>{expectedItc}%</Value>
          </Item>
        </div>
        <hr style={{ margin: 'var(--large) 0' }} />
        <div style={{ color: 'var(--color-black)', fontWeight: 'bold', textAlign: 'center' }}>
          <div>
            Total cost to you (bid + Conductor fee)
          </div>
          <div style={{ color: 'var(--color-primary' }}>
            {formatDollars(total)}
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div style={{ display: 'flex', marginLeft: 'auto', gap: '1rem' }}>
          <Button 
            outlined 
            primary 
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button 
            primary 
            onClick={async () => {
              await submitBid();
              closeModal();
            }}
            disabled={submitBidStatus === 'loading'} 
            loading={submitBidStatus === 'loading'}
          >
            Confirm bid
          </Button>
        </div>
      </Modal.Footer>
    </Modal>

  );
}
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Button } from '~/components';
import * as dataroomApi from '~/api/dataroom';
import { useDataroomContext } from '../../providers/DataroomProvider';
import { DataroomCategory } from '~/types/dataroom';
import { usePermissions } from '~/requests/permissions/usePermissions';

export default function AdditionalDocumentsUploadButton() {
  const { permissions } = usePermissions();
  const { dataroom, updateDataroomDocument, handleDocumentTypesChange } = useDataroomContext();
  const hasSelectedDocuments = dataroom?.documents?.some(category => {
    return category?.documentTypes?.some(documentType => documentType.isSelected);
  });

  const { mutateAsync: uploadFile, status: uploadStatus } = useMutation(async (file: File) => {
    try {
      const additionalDocumentsCategory = dataroom?.documents?.find(d => d.code === 'ADDITIONAL_DOCUMENTS') as DataroomCategory;
      const categoryId = additionalDocumentsCategory.id;
      const documentType = await dataroomApi.createDocumentType(dataroom.id, {
        categoryId,
        name: file.name,
        description: '',
        isUserProvidedName: false
      });

      const newDocument = await dataroomApi.uploadFile({
        id: dataroom.id,
        documentTypeId: documentType.id,
        isCustom: true,
        fileData: file,
        isUserProvidedName: false
      });

      toast.success('Document uploaded successfully.', { duration: 3000 });
      additionalDocumentsCategory.documentTypes.push(documentType);
      handleDocumentTypesChange(additionalDocumentsCategory.id, documentType);
      setTimeout(() => {
        updateDataroomDocument({ dataroom, documentTypeId: documentType.id, newDocument });
      });
    } catch (err) {
      console.error(err);
      toast.error('Error occurred uploading the document. Please refresh the page and try again.', { duration: 4000 });
    }
  });

  const { getRootProps, isDragActive, getInputProps } = useDropzone({   
    maxFiles: 1,
    async onDrop(acceptedFiles) {
      if (acceptedFiles?.length !== 1) {
        toast.error('Only one file can be uploaded for a row.');
      } else {
        await uploadFile(acceptedFiles[0]);
      }
    }
  });

  if (!permissions.canUpdateDataroom) {
    return null;
  }

  return (
    <div {...(uploadStatus === 'loading' ? {} : getRootProps())}>
      <input {...getInputProps()} />
      <Button
        outlined
        primary
        fluid
        style={isDragActive ? {
          background: 'var(--color-primary-light)',
          border: '1px dashed var(--color-primary)',
          color: 'var(--color-primary)',
          boxShadow: 'none !important' // Use of !important is needed to override Semantic style
        } : {}}
        disabled={uploadStatus === 'loading'}
        loading={uploadStatus === 'loading'}
      >
        Have {hasSelectedDocuments ? 'additional' : ''} documents to add? - click or drag-and-drop here to upload
      </Button>
    </div>
  );
}
import { useEffect, useReducer, useState } from 'react';
import { Icon, Loader, TableCell } from 'semantic-ui-react';
import { orderBy } from 'lodash';
import * as S from '../styles';
import Table from '../../../../components/Table';
import { useTeamMembersQuery } from '~/requests/team';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import InviteTeamMemberModal from '../InviteTeamMemberModal';
import { format } from 'date-fns';
import { usePromiseWatcher, useUserContext } from '~/hooks';
import { inviteTeammate } from '~/api/team';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { getListTeamMembersKey } from '~/requests/team';
import Link from '~/components/Link';
import { Button } from '~/components';
import DefaultProjectLeadCheckbox from './DefaultProjectLeadCheckbox';
import styled from 'styled-components';
import CompanyRoleTableCell from './CompanyRoleTableCell';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { RemoveUserButton } from './styles';
import RemoveUserModal from '~/modals/RemoveUserModal';
import sendSettingsClickEvent from '~/utils/analytics/sendSettingsClickEvent';

const StyledTable = styled(Table)<{ noPadding: boolean }>`
  ${props => props.noPadding && `
    &&&.table tr td { padding-top: 0; padding-bottom: 0; }
  `}
`;

function sortReducer(state: any, action: any) {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        data: orderBy(action.data, [state.column], [state.direction])
      };
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.slice().reverse(),
          direction:
            state.direction === 'asc' ? 'desc' : 'asc',
        };
      } 

      return {
        column: action.column,
        data: orderBy(state.data, [action.column], ['asc']),
        direction: 'asc',
      };
    default:
      throw new Error();
  }
}

const TeamMembers = () => {
  const { data: teamMembers, status: fetchTeamStatus } = useTeamMembersQuery();
  const { openModal } = useModalContext();
  const [email, setEmail] = useState('');
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();
  const { loggedInUser } = useUserContext() as any;
  const isConductorAdmin = loggedInUser.isAdmin;
  const [userToRemove, setUserToRemove] = useState();

  const [state, dispatch] = useReducer(sortReducer, {
    column: 'lastAccess',
    data: teamMembers,
    direction: 'desc',
  });

  useEffect(() => {
    dispatch({ 
      type: 'INIT', 
      data: teamMembers?.map((member: any) => ({ 
        ...member, 
        lastAccess: member.isTeamInvite ? member.createdAt && new Date(member.createdAt) : member.lastAccess && new Date(member.lastAccess)
      }))
    });
  }, [teamMembers]);
  
  const getSort = (column: any) => {
    const sort = state.column === column ? state.direction : undefined;
    if (sort === 'asc') return 'ascending';
    if (sort === 'desc') return 'descending';
    return sort;
  };

  const updateSort = (column: any) => {
    return dispatch({ type: 'CHANGE_SORT', column });
  };

  const {execute: inviteTeamMember, status: inviteStatus} = usePromiseWatcher(
    () => inviteTeammate(email).then(() => {
     // Clear team member cache
     queryClient.invalidateQueries(getListTeamMembersKey());

     // Set a success toast
     toast.success('Successfully resent team member invite');
   }),
   `re-inviting ${email} to your team`,
   [email]
 );

  useEffect(() => {
    if (email) {
      inviteTeamMember();
      setEmail('');
  }}, [email]);


  return (
      <>
      <S.ButtonContainer>
          <S.Message>
            Below is a listing of all members of your organization that are active on Conductor Solar. To invite a teammate, click the button to the right.
            {permissions.isCompanyAdmin && (
              <>
                <br /><br />
                In the table below you can specify the roles of the users in your organization. {permissions.isInvestor && 'Default project leads will be the first users assigned to projects when you’re invited to bid.'}
              </>
            )}
          </S.Message>
          <Button 
            style={{ marginRight: '0' }}
            primary
            size="medium"
            onClick={() => {
              openModal(InviteTeamMemberModal);
              // Analytics
              sendSettingsClickEvent('Invite User');
            }}>
              Invite new user
          </Button>
        </S.ButtonContainer>
        <S.TableWrapper loading={fetchTeamStatus === 'loading'}>
          <div className="table-loader-overlay"></div>
          <Loader active={fetchTeamStatus === 'loading'} size="big" />
          <StyledTable sortable celled noPadding={permissions.isCompanyAdmin && permissions.isInvestor}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={getSort('name')}
                  onClick={() => updateSort('name')}>
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={getSort('email')}
                  onClick={() => updateSort('email')}>
                  E-mail address
                </Table.HeaderCell>
                {!isConductorAdmin && (
                  <Table.HeaderCell
                    textAlign="center"
                    sorted={getSort('companyRoleId')}
                    onClick={() => updateSort('companyRoleId')}
                  >
                    User role
                  </Table.HeaderCell>
                )}
                {permissions.isInvestor && (
                  <Table.HeaderCell textAlign="center">
                    Default project lead
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell
                  textAlign="center"
                  sorted={getSort('lastAccess')}
                  onClick={() => updateSort('lastAccess')}>
                  Last active on
                </Table.HeaderCell>
                {permissions.isCompanyAdmin && !permissions.isAdmin && (
                  <Table.HeaderCell textAlign="center">
                    Remove user
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.data?.map((member: any) => {
                return (
                  <Table.Row key={(member.isTeamInvite ? 'teamInvite' : 'user') + '-' + member.id}>
                    <Table.Cell style={{ maxWidth: '18.75rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                      {member.name}
                    </Table.Cell>
                    <Table.Cell 
                      // Cut off email address at a certain width for company admins. 
                      // They have more actions so the table is wider and the email address can be cut off to allow for more room.
                      style={permissions.isCompanyAdmin && !permissions.isAdmin 
                        ? { maxWidth: '18.5rem', overflow: 'hidden', textOverflow: 'ellipsis' } 
                        : {}}
                    >
                      {member.email}
                    </Table.Cell>
                    {!isConductorAdmin && (
                      <>
                        <CompanyRoleTableCell member={member} />
                        {permissions.isCompanyAdmin && permissions.isInvestor && (
                          <Table.Cell textAlign="center" style={{ padding: 0 }}>
                            <DefaultProjectLeadCheckbox member={member} />
                          </Table.Cell>
                        )}
                        {!permissions.isCompanyAdmin && permissions.isInvestor && (
                          <Table.Cell textAlign="center" style={{ padding: 0 }}>
                            {member.isDefaultProjectLead ? 'Yes' : 'No'}
                          </Table.Cell>
                        )}
                      </>
                    )}
                    <Table.Cell textAlign='center'>
                      {member.isTeamInvite ? (
                        <>
                          Invited on {format(new Date(member.createdAt), 'M/dd/yyy')}
                          <span style={{ padding: '0 var(--2x-small)' }}>-</span>
                          <Link 
                            underline
                            as={Button}
                            onClick={() => setEmail(member.email)}
                            disabled={inviteStatus === 'pending'}
                          >
                            Resend invite
                          </Link>
                        </>
                        ) : ( 
                          member.lastAccess ? format(new Date(member.lastAccess), 'M/dd/yyyy') : ''
                        )}
                    </Table.Cell>
                    {permissions.isCompanyAdmin && !permissions.isAdmin && (
                      <TableCell textAlign='center' style={{ display: 'flex', justifyContent: 'center', padding: 0 }} >
                        <RemoveUserButton onClick={() => {
                          // Analytics
                          sendSettingsClickEvent('Remove User');
                          
                          if (member.id === loggedInUser?.id && !member.isTeamInvite) {
                            toast.error('You cannot remove yourself as a user. Please contact support or another company admin to remove you.');
                          } else {
                            setUserToRemove(member);
                          }
                        }}>
                          <Icon name="trash alternate outline" />
                        </RemoveUserButton>
                      </TableCell>
                    )}
                 </Table.Row>
                );
              })}
          </Table.Body>
          {userToRemove && <RemoveUserModal user={userToRemove} closeModal={() => setUserToRemove(undefined)} />}
        </StyledTable>
      </S.TableWrapper>
    </>
  );
};

export default TeamMembers;

import ReactGA from 'react-ga4';

export default function sendCustomPortfolioEvent(category: string, label: string, portfolioName: string, portfolioId: string, additionalParams: any = {}) {
   const formattedName = `${portfolioName} (${portfolioId})`;   
   
   ReactGA.event(label, {
      event_category: category,
      portfolio_name: formattedName,
      ...additionalParams
   });
}
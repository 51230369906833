import { ContractType, PricingType } from '~/constants';
import { Project } from '~/types/project';

export default function bidUnit(project: Project) {
  if (project?.pricingType === PricingType.OFFTAKE && ( project.contractType === ContractType.LEASE || project.contractType === ContractType.ENERGY_SERVICE_AGREEMENT)) {
    return '$ / year';
  } else if (project?.pricingType === PricingType.OFFTAKE) {
    return '$ / kWh';
  } else if (project?.pricingType === PricingType.ITC) {
    return '% of ITC / $';
  } else {
    return 'Total $ Value';
  }
}
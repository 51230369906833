import Modal from '~/components/Modal';
import { Button } from '~/components';
import BudgetTable from '../../BudgetTable';

export interface BidConfirmationModalProps {
  closeModal: Function;
  submitBid: Function;
  submitBidStatus: string;
  budgetDisplayValues: any;
  modalPricingType:'ACQUISITION' | 'OFFTAKE' | 'ITC' | 'DEV_FEE' | 'BRIDGE_LOAN';
  bidAmount: any;
}

export default function BidConfirmationModal({
  closeModal,
  submitBid,
  submitBidStatus,
  budgetDisplayValues,
  modalPricingType,
  bidAmount
}: BidConfirmationModalProps) {
  const values = { type: 'budget', data: budgetDisplayValues };

  return (
  <Modal closeModal={submitBidStatus === 'loading' ? undefined : closeModal}>
    <Modal.Title>Confirm your bid</Modal.Title>
    <Modal.Content>
      {modalPricingType === 'OFFTAKE' ?  (
        <p>Your bid details are presented below for your review. Please note that the bid you entered is for the offtake rate. The Conductor fee is calculated as a percentage of all ITC-eligible expenses. Developers do not see the Conductor Solar fee, but they are aware that investors pay Conductor a success fee.</p>) 
      : (
        <p>Your bid details are presented below for your review. Please note that the bid you entered is for the EPC cost only. The Conductor fee is calculated as a percentage of all ITC-eligible expenses. Developers do not see the Conductor Solar fee, but they are aware that investors pay Conductor a success fee.</p>
      )}
      <BudgetTable 
        values={values}
        format="responsive"
        pricingType={modalPricingType} 
        hasDividerLine
        bidAmount={bidAmount} 
      />
    </Modal.Content>
    <Modal.Footer>
      <Button 
        outlined 
        primary 
        onClick={() => closeModal()}
      >
        Cancel
      </Button>
      <Button 
        primary 
        onClick={async () => {
          await submitBid();
          closeModal();
        }}
        disabled={submitBidStatus === 'loading'} 
        loading={submitBidStatus === 'loading'}
      >
        Confirm bid
      </Button>
    </Modal.Footer>
</Modal>

  );
}
import { Table } from '~/components';
import { useNotificationPreferencesQuery } from '~/requests/user';
import NotificationSettingCheckbox from './NotificationSettingCheckbox';
import { CategoryNameMap } from './constants';
import SettingsPageLoader from '../SettingsPageLoader';
import { useFadeInStyles } from '~/hooks';
import styled from 'styled-components';
import SidebarTabs from '~/components/SidebarTabs';
import { useState } from 'react';
import { usePermissions } from '~/requests/permissions/usePermissions';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import EmailOptOut from './EmailOptOut';
import { useTeamQuery } from '~/requests/team';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

const NotificationSettingsContainer = styled.div`
  display: flex;
  > table.ui.table {
    margin-top: 0;
  }
  > * + .notification-table {
    margin-left: var(--small);
  }
`;

const NotificationSettings = () => {
  const location = useLocation();
  const history = useHistory();
  const { data, status: notificationPreferencesStatus } = useNotificationPreferencesQuery();
  const fadeInStyles = useFadeInStyles();
  const { permissions } = usePermissions();
  const { data: team, status: teamStatus } = useTeamQuery();
  const showNonMemberColumn = permissions.isCompanyAdmin || team?.projectVisibility === 'ALL';
  const isLoading = notificationPreferencesStatus === 'loading' || teamStatus === 'loading';

  useAnalyticsPageView('/settings/notifications', 'Notification Settings');

  // Determine sidebar height based on the number of rows
  let sidebarHeight = undefined;
  if (data?.developer || data?.investor) {
    const numRows = Math.max(data.developer?.length ?? 0, data.investor?.length ?? 0);
    sidebarHeight = (numRows * 40 + 72) / 16 + 'rem';
  }

  const [group, setGroup] = useState<'developer' | 'investor'>(() => {
    // Get group from query params if it exists
    const queryParams = queryString.parse(location.search);
    const groupParam = queryParams?.group as string;
    if (groupParam === 'developer' || groupParam === 'investor') {
      return groupParam as 'developer' | 'investor';
    } else {
      // Remove group query param if it is set
      if (groupParam) {
        delete queryParams.group;
        history.replace(location.pathname + '?' + queryString.stringify(queryParams));
      }

      // Return the group based on the user's permissions
      return permissions.hasDeveloperRole ? 'developer' : 'investor';
    }
  });

  const selectGroup = (group: 'developer' | 'investor') => {
    setGroup(group);
    // Update group query param
    window.history.pushState({}, '', `?group=${group}`);
  };

  const hasBothGroups = permissions.hasDeveloperRole && permissions.hasInvestorRole;

  if (isLoading) {
    return <SettingsPageLoader />;
  }

  // Filter out externalUserExempt rows if necessary
  let rows = data?.[group] ?? [];
  if (group === 'developer' && !permissions.isDeveloper) {
    rows = rows.filter(row => !row.externalUserExempt);
  } else if (group === 'investor' && !permissions.isInvestor) {
    rows = rows.filter(row => !row.externalUserExempt);
  }

  return (
    <div style={fadeInStyles}>
      {!hasBothGroups && (
        <div style={{ marginBottom: 'var(--small)' }}>
          <EmailOptOut />
        </div>
      )}
      <NotificationSettingsContainer>
        {hasBothGroups && (
          <SidebarTabs style={{ height: sidebarHeight }}>
            <SidebarTabs.Tab
              active={group === 'developer'}
              onSelect={() => selectGroup('developer')}
            >
              Developer-side notifications
            </SidebarTabs.Tab>
            <SidebarTabs.Tab
              active={group === 'investor'}
              onSelect={() => selectGroup('investor')}
            >
              Investor-side notifications
            </SidebarTabs.Tab>
            {hasBothGroups && <div style={{ marginTop: 'var(--x-large)' }}><EmailOptOut /></div>}
          </SidebarTabs>
        )}
        <div className="notification-table" style={{ width: '100%' }}>
          {hasBothGroups ? (
            <p>
              Use the table below to set your notification preferences for when you’re partnered with a {group}. Note that the selections you make will impact both in-app and e-mail notifications. 
            </p>
          ) : (
            <p>
              Use the table below to set your notification preferences. Note that the selections you make will impact both in-app and e-mail notifications. 
            </p>
          )}
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="center">I want notifications when...</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">{CategoryNameMap.LEAD}</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">{CategoryNameMap.MEMBER}</Table.HeaderCell>
                {showNonMemberColumn && <Table.HeaderCell textAlign="center">{CategoryNameMap.NON_MEMBER}</Table.HeaderCell>}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows.map(item => (
                <Table.Row key={`${group}-notification-preference-${item.code}`}>
                  <Table.Cell style={{ paddingTop: 0, paddingBottom: 0 }}>
                    {item.name}
                  </Table.Cell>
                  {item.selections.map(selection => {
                    if (selection.code === 'NON_MEMBER' && !showNonMemberColumn) {
                      return null;
                    }

                    return (
                      <Table.Cell 
                        style={{ padding: 0 }}
                        key={`${group}-${selection.code}-${item.code}`}
                        textAlign="center"
                      >
                        <NotificationSettingCheckbox
                          code={item.code}
                          name={item.name}
                          group={group}
                          category={selection.code}
                          checked={selection.selected}
                          disabled={!selection.updateable}
                        />
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </NotificationSettingsContainer>
    </div>
  );
};

export default NotificationSettings;
import React, { useEffect, useState } from 'react';
import { getUsers } from 'api/admin';
import { Button } from '~/components';
import { Table } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';
import { usePromiseWatcher } from 'hooks';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

interface CellProps {
  selected: boolean;
  children: React.ReactNode;
  rest?: any;
}

const AdminUsers = () => {
  const location = useLocation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [sort, setSort] = useState({
    column: 'name',
    direction: 'ascending',
  });

  useAnalyticsPageView('/users', 'Users');

  const Cell = ({selected, children, ...rest}: CellProps) => (
    <Table.Cell style={{fontWeight: selected && 'bold', padding: '10px'}} {...rest}>
      {children}
    </Table.Cell>
  );

  const {execute: executeGetUsers, value: users} = usePromiseWatcher(
    () => getUsers({
      sortProperty: sort.column,
      sortOrder: sort.direction === 'ascending' ? 'asc' : 'desc',
    }),
    'loading the users list',
    [sort],
    {defaultValue: []} as any
  ) as any;

  useEffect(() => {
    executeGetUsers();
  }, [sort]);

  // Handle sort toggle
  const {column, direction} = sort as any;
  const toggleSort = (column: any) => {
    let direction;
    if (sort.column === column) {
      direction = sort.direction === 'ascending' ? 'descending' : 'ascending';
    } else {
      direction = 'ascending';
    }
    setSort({column, direction});
  };

  return (
    <Table celled padded sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === 'name' ? direction : null}
            onClick={() => toggleSort('name')}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'email' ? direction : null}
            onClick={() => toggleSort('email')}
          >
            Email
          </Table.HeaderCell>
          <Table.HeaderCell>
            Spoofing
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'companyName' ? direction : null}
            onClick={() => toggleSort('companyName')}
          >
            Company
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'role' ? direction : null}
            onClick={() => toggleSort('role')}
          >
            Role
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'createdAt' ? direction : null}
            onClick={() => toggleSort('createdAt')}
          >
            Created
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'lastLogin' ? direction : null}
            onClick={() => toggleSort('lastLogin')}
          >
            Last Login
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'lastLoginFailure' ? direction : null}
            onClick={() => toggleSort('lastLoginFailure')}
          >
            Last Login Failure
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'lastAccess' ? direction : null}
            onClick={() => toggleSort('lastAccess')}
          >
            Last Access
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {users?.map((d: any) => (
          !d.isTeamInvite &&
            <Table.Row
              key={d.id}
              style={{
                cursor: 'pointer',
                background: selectedRow === d.id ? 'rgba(0,0,0,.05)' : 'rgba(0,0,0,0)',
              }}
              onClick={() => setSelectedRow(d.id)}
            >
              <Cell selected={selectedRow === d.id}>
                {d.name}
              </Cell>
              <Cell selected={selectedRow === d.id}>
                {d.email}
              </Cell>
              <Cell selected={selectedRow === d.id}>
                <Button as="a"
                  secondary
                  size="small"
                  href={
                    '/dashboard' + location.search +
                    (location.search.indexOf('?') < 0 ? '?' : '&') +
                    'spoof=' + encodeURIComponent(d.email)
                  }
                  target="_blank"
                >
                  Spoof!
                </Button>
              </Cell>
              <Cell selected={selectedRow === d.id}>
                {d.companyName}
              </Cell>
              <Cell selected={selectedRow === d.id}>
                {d.role}
              </Cell>
              <Cell selected={selectedRow === d.id}>
                {d.createdAt ? new Date(d.createdAt).toLocaleDateString('en', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }) : null}
              </Cell>
              <Cell selected={selectedRow === d.id}>
                {d.lastLogin ? new Date(d.lastLogin).toLocaleString('en', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                }) : null}
              </Cell>
              <Cell selected={selectedRow === d.id}>
                {d.lastLoginFailure ? new Date(d.lastLoginFailure).toLocaleString('en', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                }) : null}
              </Cell>
              <Cell selected={selectedRow === d.id}>
                {d.lastAccess ? new Date(d.lastAccess).toLocaleString('en', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: '2-digit',
                }) : null}
              </Cell>
            </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default AdminUsers;
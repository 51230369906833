import { useState } from 'react';
import { DealStates } from '~/constants';
import { usePortfolioContext } from '~/hooks';
import { Form, Popup, Icon } from 'semantic-ui-react';
import { Button } from '~/components';
import PortfolioTheme from '~/components/PortfolioTheme';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import { PortfolioSubmissionModal } from './PortfolioSubmissionModal';
import PortfolioPriceCheckModal from './PortfolioPriceCheckModal';

export default function PortfolioSubmissionButtons() {
  const { openModal } = useModalContext();
  const { projectBlobs, portfolioState } = usePortfolioContext();
  const [showSubmissionModal, setShowSubmissionModal] = useState(false);
  const readyForSubmission = portfolioState?.earliestState === DealStates.RETURNED_TO_DRAFT || portfolioState?.earliestState === DealStates.READY_TO_SUBMIT;
  const hasErrors = portfolioState?.earliestState === DealStates.PROJECT_HAS_ERRORS;

  return (
    <PortfolioTheme style={{ display: 'flex', alignItems: 'center', gap: 'var(--x-small)'}}>
      {readyForSubmission ? (
        <Form>
          <Button 
            primary  
            onClick={() => {
              if ((new Set(projectBlobs.map(({ project }: any) => project.pricingType))).size > 1) {
                openModal(PortfolioPriceCheckModal);
              } else {
                setShowSubmissionModal(true);
              }
            }}
          >
            Submit portfolio 
          </Button>
        </Form>
      ) : (
        hasErrors && (
          <>
            <Popup
              position="bottom center"
              trigger={<Icon name="info circle" size="large" style={{ color: 'var(--color-charcoal)' }} tabIndex={0} />}
              content={
                'One or more of the projects in your portfolio is incomplete. Once you’ve addressed any outstanding issues, you will be able to submit your portfolio from here.'
              }          
              inverted
              on={['hover', 'focus']}
            />
            <Form>
              <Button
                primary
                size='medium'
                disabled
              >
                Projects are incomplete
              </Button>
            </Form>
          </>
        )
      )}
      {showSubmissionModal && 
        <PortfolioSubmissionModal 
          closeModal={() => setShowSubmissionModal(false)} 
          isStandaloneProject={projectBlobs.length === 1}
        />}
    </PortfolioTheme>
  );
}  
import ReactGA from 'react-ga4';

export default function sendPortfolioClickEvent(label: string, portfolioName: string, portfolioId: string, additionalParams: any = {}) {
   const formattedName = `${portfolioName} (${portfolioId})`;   
   
   ReactGA.event('Click - ' + label, {
      event_category: 'Click',
      portfolio_name: formattedName,
      ...additionalParams
   });
}
import ReactGA from 'react-ga4';

export default function sendCustomProjectEvent(category: string, label: string, projectName: string, projectId: string, additionalParams: any = {}) {
   const formattedName = `${projectName} (${projectId})`;   
   
   ReactGA.event(label, {
      event_category: category,
      project_name: formattedName,
      ...additionalParams
   });
}
import { useProjectTeamQuery } from '~/requests/team';
import TeamManagementTable from './TeamManagementTable';
import ExternalUserInvite from './ExternalUserInvite';
import { usePageType, useProjectContext } from '~/hooks';
import { usePermissions } from '~/requests/permissions/usePermissions';
import { Loader } from 'semantic-ui-react';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

const TeamManagement = () => {
  const { status } = useProjectTeamQuery();
  const pageType = usePageType();
  const { project } = useProjectContext();
  const { permissions } = usePermissions();
  const label = pageType === 'project' && !project?.portfolioId ? 'project' : 'portfolio';

  // Analytics
  useAnalyticsPageView('/team', 'Team Management');

  if (status === 'loading') {
    return (
      <div style={{ height: 'calc(100vh - 12rem)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loader active size="large" />
      </div>
    );
  }

  return (
    <div style={{ paddingTop: 'var(--large)', paddingBottom: 'var(--x-large)' }}>
      <p>Use the table below to add and remove individuals from your {label} team and to define their {label} role.</p>
      <TeamManagementTable />
      {permissions.canAddOrRemoveTeamMember && <ExternalUserInvite />}
    </div>
  );
};

export default TeamManagement;
import * as S from './styles';
import { useEffect } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { usePortfolioContext } from '~/hooks';
import { useQueryClient } from 'react-query';
import { usePageType } from '~/hooks';
import { useGrantInvestorDataroomAccess } from '~/mutations/dataroom/investorAccess/useGrantInvestorDataroomAccess';
import { useRevokeInvestorDataroomAccess } from '~/mutations/dataroom/investorAccess/useRevokeInvestorDataroomAccess';
import { useInvestorDataroomAccess } from '~/requests/dataroom';
import { DealStates } from '~/constants';
import useArchiveToast from '~/hooks/useArchiveToast';
import { getInvestorProjectDataroomAccessKey } from '~/requests/dataroom';

export default function DataroomAccess() {
  const { portfolio, portfolioDisplayValues, projectBlobs } = usePortfolioContext();
  const queryClient = useQueryClient();
  const pageType = usePageType();
  const investors = portfolioDisplayValues?.dealSummary?.investors ?? [];

  const { data: investorDataroomAccess, status: getInvestorProjectDataroomAccessStatus, refetch: refetchInvestorDataroomAccess } = useInvestorDataroomAccess();
  const { mutate: grantInvestorDataroomAccess, status: grantInvestorDataroomAccessStatus } = useGrantInvestorDataroomAccess();
  const { mutate: revokeInvestorDataroomAccess, status: revokeInvestorDataroomAccessStatus } = useRevokeInvestorDataroomAccess();
  const loadingDataroomAccess = getInvestorProjectDataroomAccessStatus === 'loading' || grantInvestorDataroomAccessStatus === 'loading' || revokeInvestorDataroomAccessStatus === 'loading';

  const { showArchiveToast } = useArchiveToast();

  const hasDataroomAccess = (investorId: string) => {
    return !!investorDataroomAccess?.investors?.find((row: any) => row.investorId === investorId);
  };

  const toggleDataroomAccess = async (investorId: string) => {
    if (showArchiveToast()) {
      return;
    }

    if (hasDataroomAccess(investorId)) {
      // @ts-ignore
      await revokeInvestorDataroomAccess({ investorId }, { onSuccess: refetchInvestorDataroomAccess });
    } else {
      // @ts-ignore
      await grantInvestorDataroomAccess({ investorId }, { onSuccess: refetchInvestorDataroomAccess });
    }
  };

  // This is required to keep dataroom access in sync with portfolio's bid summary
  useEffect(() => {
    if (pageType === 'portfolio') {
      queryClient.invalidateQueries(getInvestorProjectDataroomAccessKey(pageType, portfolio?.id));
    }
  }, [pageType, portfolio?.id, queryClient]);
  


  return (
    <>
      <S.DealSummaryLabel>
        Grant access to documents
      </S.DealSummaryLabel>
      {investors.map((investor: any) => {
        const blobs = projectBlobs.filter((projectBlob: any) => projectBlob?.project?.investorId === investor?.id);
        const isDeclined = !blobs.some((blob: any) => blob?.projectState?.dealState !== DealStates.DECLINED);

        if (isDeclined) {
          return <S.DealSummaryCell key={investor.id} />;
        } else {
          return (
            <S.DealSummaryCell key={investor.id}>
              <Checkbox
                checked={hasDataroomAccess(investor.id)}
                onChange={() => toggleDataroomAccess(investor.id)}
                disabled={loadingDataroomAccess}
              />
            </S.DealSummaryCell>
          );
        }
      })}
    </>
  );
}
import React, { ReactNode, createContext, useCallback, useContext, useEffect } from 'react';
import { useQueryParamState } from '~/hooks/useQueryParamState';
import { useConversationListQuery } from '~/requests/conversations/useConversationListQuery';
import { Conversation } from '~/types/conversations/Conversation';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

interface ConversationContextProps {
  conversations: Conversation[];
  supportConversations: Conversation[];
  activeConversations: Conversation[];
  inactiveConversations: Conversation[];
  selectedConversation: Conversation | null;
  isSupportConversation: boolean;
  setConversation: (conversation: string | Conversation) => void;
  conversationsStatus: 'loading' | 'error' | 'success' | 'idle';
}

const ConversationContext = createContext<ConversationContextProps>({
  conversations: [],
  conversationsStatus: 'loading',
  supportConversations: [],
  activeConversations: [],
  inactiveConversations: [],
  selectedConversation: null,
  isSupportConversation: false,
  setConversation: () => {}
});

export const useConversationContext = () => useContext(ConversationContext);

export const ConversationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const history = useHistory();

    // Set up the query param state
  const [conversationProviderState, setConversationProviderState] = useQueryParamState('c');

  // Get conversations list data. This data should already be sorted alphabetically by name
  const { data: conversations, status: conversationsStatus } = useConversationListQuery();

  const selectedConversation = conversations?.find(c => c.id === conversationProviderState?.selectedConversationId) ?? null;

  // Parse support conversation, active conversations, and inactive conversations
  const supportConversations = conversations?.filter(c => c.type === 'SUPPORT') ?? [];
  const partyConversations = conversations?.filter(c => c.type === 'PARTIES') ?? [];
  const activeConversations = partyConversations
    ?.filter(c => c.isActive) 
    ?.map(conversation => ({ ...conversation, isActive: true }))
    ?? [];
  const inactiveConversations = partyConversations
    ?.filter(c => !c.isActive)
    ?.map(conversation => ({ ...conversation, isActive: false }))
    ?? [];

  // Determine default selected conversation.
  // 1. If navigating as an admin from open questions dashboard filter, default to first support conversation
  // 2. If an investorId query param is passed in, find the conversation for that investor
  // 3. Otherwise, select the first default to the first active conversation in the list
  //TODO if navigateToSupportConversation is set, set id to first support convo in the list
  
  const queryParams = new URLSearchParams(location.search);
  const navigateToSupportConversation = queryParams.get('navigateToSupportConversation'); 
  const paramInvestorId = queryString.parse(location?.search)?.investorId;
  const paramConversationId = queryString.parse(location?.search)?.conversationId;
  useEffect(() => {
    if (conversations?.length && !conversationProviderState?.selectedConversationId) {
      const conversationId =
      (navigateToSupportConversation && supportConversations?.[0]?.id)
          ?? (paramInvestorId && partyConversations?.find(conversation => conversation.investorId === paramInvestorId)?.id) 
          ?? (paramConversationId && conversations?.find(conversation => conversation.id === paramConversationId)?.id)
          ?? activeConversations?.[0]?.id
          ?? supportConversations?.[0]?.id;
      if (conversationId !== conversationProviderState?.selectedConversationId) {
        setConversationProviderState({ 
          ...conversationProviderState,
          selectedConversationId: conversationId
        });
      }
    }
  }, [partyConversations, paramInvestorId, paramConversationId, conversations, supportConversations]); 

  useEffect(() => {
    if ((paramInvestorId || paramConversationId) && conversationProviderState?.selectedConversationId) {
      // Update history to remove the investorId query param but keep the rest of the query params
      const newSearch = queryString.stringify({
        ...queryString.parse(location.search),
        investorId: undefined,
        conversationId: undefined
      });
      history.replace(location.pathname + (newSearch ? '?' + newSearch : '')); 
    }
  }, [paramInvestorId, paramConversationId, conversationProviderState]);

  // Setter for selecting the conversation to view messages for
  // Can take a conversation objection or a conversation ID
  const setConversation = useCallback((conversation: string | Conversation) => {
    setConversationProviderState({
      ...conversationProviderState,
      selectedConversationId: typeof conversation === 'string' ? conversation : conversation.id
    });
  }, [conversationProviderState]);

  return (
    <ConversationContext.Provider value={{
      conversations: [...partyConversations, ...supportConversations],
      conversationsStatus,
      supportConversations,
      activeConversations,
      inactiveConversations,
      selectedConversation,
      isSupportConversation: selectedConversation?.type === 'SUPPORT',
      setConversation,
    }}>
      {children}
    </ConversationContext.Provider>
  );
};


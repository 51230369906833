import { useEffect, useState } from 'react';
import { Button, Modal } from '~/components';
import { useForm, usePageType, useProjectContext, useProjectTeamQueryApi } from '~/hooks';
import { withForm } from '~/utils/withForm';
import { FormSchemaAwareWidget } from '../form';
import { Form } from 'semantic-ui-react';
import { useExternalTeamMembersQuery } from '~/requests/team/useExternalTeamMembersQuery';
import { useMutation } from 'react-query';
import toast from 'react-hot-toast';
import { useProjectTeamQuery } from '~/requests/team';
import { User } from '~/types/user';
import { TeamMember } from '~/types/teamManagement';
import sendProjectClickEvent from '~/utils/analytics/sendProjectClickEvent';
import sendPortfolioClickEvent from '~/utils/analytics/sendPortfolioClickEvent';

const defaults = { email: '' };

const ExternalUserInvite = withForm(
  { defaults }, 
  () => {
    const [newUser, setNewUser] = useState<string | null>(null);
    const { team } = useProjectTeamQuery();
    const [modalOpen, setModalOpen] = useState(false);
    const pageType = usePageType();
    const { project } = useProjectContext();
    const label = pageType === 'project' && !project?.portfolioId ? 'project' : 'portfolio';
    const { formState, updateFormValue } = useForm() as any;
    const { data: externalUsers, status } = useExternalTeamMembersQuery();
    const [error, setError] = useState<string | null>(null);
    const [hasClicked, setHasClicked] = useState(false);
    const { inviteExternalTeamMember } = useProjectTeamQueryApi();

    useEffect(() => {
      updateFormValue('email', '');
      setHasClicked(false);
    }, [modalOpen]);

    const userTeamMap = team?.reduce((acc: { [key: string]: true }, member: TeamMember) => {
      if (member.userId) {
        acc[member.userId] = true;
      }
      return acc;
    }, {} as { [key: string]: true });
    const dropdownUserList = externalUsers?.filter((user: User) => !userTeamMap[user.id]);

    useEffect(() => {
      const { email } = formState;
      if (!email) {
        setError('Required');
        return;
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        setError('Invalid email');
        return;
      }

      setError(null);
    }, [formState.email]);

    const { mutateAsync: inviteExternalUser, status: submitStatus } = useMutation(inviteExternalTeamMember);
    const handleSubmit = async () => {
      setHasClicked(true);
      if (!error) {
        try {
          await inviteExternalUser(formState.email);
          toast.success('External team member added successfully.', { duration: 4000 });
          // Analytics
          label === 'project' ? sendProjectClickEvent('Team Management Update - External Invite', project.name, project.id) : sendPortfolioClickEvent('Team Management Update - External Invite', project.name, project.id);
          setNewUser(null);
          setModalOpen(false);
        } catch (err: any) {
          console.error(err?.response);
          if (err?.response?.data?.message === 'USER_ALREADY_ON_TEAM') {
            toast.error(`The user you're attempting to add is already part of the ${label}.`);
          } else {
            toast.error('An error occurred while sending the invitation. Please try again.');
          }
        }
      }
    };

    const isLoadingExternalUsers = modalOpen && status === 'loading';
    const isInviting = submitStatus === 'loading';

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            primary 
            onClick={() => setModalOpen(true)} 
            disabled={isLoadingExternalUsers} 
            loading={isLoadingExternalUsers}>
            Invite someone from outside of your organization
          </Button>
        </div>

        {modalOpen && !isLoadingExternalUsers && (
          <Modal closeModal={() => setModalOpen(false)}>
            <Modal.Title>Invite external {label} team member</Modal.Title>
            <Modal.Content>
              <p>
                This person will receive an email from Conductor Solar and needs to create a Conductor Solar account
                through that link in order to automatically join your {label} team.
              </p>
              <Form>
                {dropdownUserList?.length ? (
                  <FormSchemaAwareWidget
                    schemaKey="email"
                    placeholder="Select a previous partner or invite a new one via email"
                    options={newUser 
                      ? [
                        { key: newUser, value: newUser, text: `New user: ${newUser}` },
                        ...externalUsers.map((user: any) => (
                          { key: user.id, value: user.email, text: `${user?.company?.name}: ${user?.name}` })
                        )
                      ]
                      : externalUsers.map((user: any) => (
                        { key: user.id, value: user.email, text: `${user?.company?.name}: ${user?.name}` })
                      )
                    }
                    search
                    selectOnBlur
                    allowAdditions
                    additionLabel='Invite user: '
                    onAddItem={(_e: any, { value }: any) => {
                      setNewUser(value as string);
                    }}
                    errorText={hasClicked && error ? error : ''}
                  />
                ): (
                  <FormSchemaAwareWidget
                    schemaKey="email"
                    fluid
                    placeholder={`Enter external ${label} team member's e-mail address`}
                    errorText={hasClicked && error ? error : ''}
                  />
                )}
              </Form>
            </Modal.Content>
            <Modal.Footer>
              <Button primary outlined onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
              <Button 
                primary 
                onClick={handleSubmit}
                loading={isInviting}
                disabled={isInviting}
              >
                Send invitation
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
);

export default ExternalUserInvite;
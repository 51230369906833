import { ContractType, ContractTypeDisplayShort, DealStates, PricingType, RevenueSource } from '~/constants';
import { getProjectBidOverallAmount } from '~/utils/calculators/costs';
import { formatNumber } from '~/utils/formatNumber';
import { Project } from '~/types/project';
import { sizeKwdc, yearOneProduction } from '~/utils/calculators';


const getOfftakeRate = (project: Project, isCondensed = false) => {
  let value = isCondensed ? 'TBD': 'Rate TBD';
  const bid = project?.pendingBid || project?.acceptedBid;
  const size = sizeKwdc(project);
  const production = yearOneProduction(project);
  const portfolioLeaseEsaRate = (project?.contractRate || 0) / (size * production);
  
  if (project.pricingType === PricingType.OFFTAKE && project.contractType === ContractType.PPA && bid) {
    // Is OFFTAKE pricing type and is PPA contract type
    value = `$${formatNumber(bid?.amount, 4)} ${isCondensed ? '' : 'per kWh'}`;
  } else if (project.pricingType === PricingType.OFFTAKE && bid) {
    // Is OFFTAKE pricing type and is LEASE or ESA contract type
    value = `$${formatNumber(getProjectBidOverallAmount(project, bid, false), 0)} ${isCondensed ? '' : 'per year'}`;
  } else if (project?.contractRate && project?.contractType === ContractType.PPA) {
    // Is PPA contract type with a contract rate set
    value = `$${formatNumber(project?.contractRate, 4)} ${isCondensed ? '' : 'per kWh'}`;
  } else if (project?.contractRate) {
    // Is LEASE or ESA contract type with a contract rate set
    // If project is in a portfolio, display the rate per kWh
    value = project?.portfolioId ? `$${formatNumber(portfolioLeaseEsaRate, 4)}` : `$${formatNumber(project?.contractRate, 0)} per year`;
  }

  // Return TBD if none of the above scenarios apply
  return value;
};

const getOfftakeValues = (project: Project, isCondensed = false) => {
  const type = project?.contractType ? ContractTypeDisplayShort[project.contractType] : undefined;
  
  const term = project?.contractTerm != null
  ? `${project.contractTerm} year${project.contractTerm > 1 && isCondensed ? 's' : ''}`
  : isCondensed ? 'TBD' : 'Term TBD';

  const escalator = project?.contractEscalator != null
    ? `${project.contractEscalator}%${isCondensed ? '' : ' Escalator'}`
    : isCondensed ? 'TBD' : 'Escalator TBD';

  return { type, term, escalator };
};

export default function offtakeRate(project: Project) {
  const rate = getOfftakeRate(project, true);
  const { type, term, escalator } = getOfftakeValues(project, true);
  
  let offtakeRateValueString: string;
  if (project.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS && !project.revenueAssumptionsFlag) {
    offtakeRateValueString = 'Community solar — investor to make own assumptions';
  } else if (project.revenueSource === RevenueSource.COMMUNITY_SOLAR_SUBS) {
    offtakeRateValueString = 'Community solar - see data for recommended assumptions';
  } else {
    const rate = getOfftakeRate(project);
    const { type, term, escalator } = getOfftakeValues(project);
    offtakeRateValueString = `${type}: ${rate}, ${term}, ${escalator}`;
  }
   
  return {
    isDisplaying: !!project.contractType,
    isHighlighted: project?.dealState >= DealStates.IN_DILIGENCE && project?.pricingType === PricingType.OFFTAKE,
    value: offtakeRateValueString,
    type,
    rate,
    term,
    escalator
  };
}
import MessagingSidebar from './components/MessagingSidebar';
import React from 'react';
import MessageCanvas from './components/MessageCanvas';
import { useConversationContext } from './providers/ConversationProvider';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

interface MessagingProps {
  pageType?: 'portfolio' | 'project';
}

const Messaging: React.FC<MessagingProps> = function() {
  const { inactiveConversations, activeConversations, supportConversations } = useConversationContext();
  const conversationLength = [...inactiveConversations, ...activeConversations, ...supportConversations]?.length ?? 0;

  // Analytics
  useAnalyticsPageView('/messages', 'Messages');

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between', 
      marginTop: 'var(--large)'
    }}>
      {conversationLength > 1 && (
        <div style={{ marginRight: 'var(--medium)' }}>
          <MessagingSidebar />
        </div>
      )}
      <MessageCanvas />
    </div>
  );
};

export default Messaging;
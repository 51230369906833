import Modal from '~/components/Modal';
import { Button } from '~/components';
import { useMutation } from 'react-query';
import { useProjectContext } from '~/hooks';
import { declineProjectBid } from '~/api/projectBids';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

export default function DeclineBidConfirmationModal({
  closeModal
}: {
  closeModal: () => void;
}) {
  const history = useHistory();
  const { project } = useProjectContext();
  const portfolioId = project?.portfolioId;
  const { mutateAsync: executeDecline, status: declineStatus } = useMutation(() => declineProjectBid(project?.id));

  return (
    <Modal closeModal={declineStatus === 'loading' ? undefined : () => closeModal()}>
      <Modal.Title>Are you sure you want to decline to bid?</Modal.Title>
      <Modal.Content>
        <p>Declining to bid will end the conversation on this project.</p>
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary
          onClick={async () => {
            try {
              const { isFinalProject } = await executeDecline();
              if (portfolioId && !isFinalProject) {
                history.push(`/portfolio/${portfolioId}`);
              } else {
                history.push('/dashboard');
              }
              toast.success('You have ended conversation on the project.', { duration: 4000 });
              closeModal();
            } catch (error) {
              console.error(error);
              toast.error('An error occurred while declining the bid. Please try again.');
            }
          }}
          disabled={declineStatus === 'loading'}
          loading={declineStatus === 'loading'}
        >
          Confirm
        </Button>
        <Button
          outlined
          primary
          onClick={() => closeModal()}
          disabled={declineStatus === 'loading'}
        >
          Cancel
        </Button>
      </Modal.Footer>
  </Modal> 
  );
}
import { formatRelative } from 'date-fns';
import React from 'react';
import { Comment } from 'semantic-ui-react';
import styled from 'styled-components';
import { useUserContext } from '~/hooks';
import { ProjectEvent } from '../types';
import { Message } from '~/types/conversations/Message';

const UppercaseDate = styled.div`
  &&& {
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--color-charcoal);
    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

const ProjectName = styled.div`
  &&& {
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: italic;
    color: var(--color-charcoal);
    margin: 0 !important; // Necessary here to override semantic-ui
  }
`;

const CommentAuthor = styled(Comment.Author)<{ active?: boolean }>`
  &&& {
    font-size: 0.875rem;
    line-height: 1rem;
    ${props => !props.active && `
      color: var(--color-charcoal);
    `}
    ${props => props.active && `
      color: var(--color-pure-black);
      font-weight: bold;
    `}
  }  
`;

const Metadata: React.FC<{ entry: Message | ProjectEvent }> = ({ entry }) => {
  const { loggedInUser } = useUserContext() as any;
  const data = (entry as ProjectEvent)?.eventData ?? (entry as Message)?.data;
  const messageData = (entry as Message)?.data;
  if (!data) return null;

  const start = entry?.createdAt as string;
  const editTimestamp = messageData?.editedAt ?? entry?.updatedAt as string;
  const isDeleted = messageData?.commentDeleted;
  const hasEdit = messageData?.updatedById;
  
  const startDate = new Date(start);
  const editedDate = new Date(editTimestamp);
  const endDate = new Date();

  const formattedStartDate = formatRelative(startDate, endDate);
  const formattedEditedDate = formatRelative(editedDate, endDate);
 
  const isLoggedInUser = loggedInUser?.id === entry?.user?.id;

  const projectName = entry?.replyThreadId ? null : (entry as Message)?.tags?.projectName ?? (entry as ProjectEvent)?.projectName;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CommentAuthor active={entry?.user?.active} as='span'>{isLoggedInUser ? 'Me' : entry?.user?.name}</CommentAuthor>
      <Comment.Metadata style={{ fontWeight: 'bold' }}>
        {projectName && <ProjectName>{projectName},&nbsp;</ProjectName>}
        <UppercaseDate style={{ fontSize: '0.75rem', lineHeight: '1rem' }}>
          {isDeleted ? `${formattedStartDate} / Deleted ${formattedEditedDate}` 
          : hasEdit ? `${formattedStartDate} / Edited ${formattedEditedDate}`
          : formattedStartDate}
        </UppercaseDate>
      </Comment.Metadata>
    </div>
  );
};

export default Metadata;
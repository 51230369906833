import React, { useState } from 'react';
import { CategoryNameMap } from './constants';
import { useMutation, useQueryClient } from 'react-query';
import { updateNotificationPreferences } from '~/api/user';
import toast from 'react-hot-toast';
import { notificationPreferencesQueryKey } from '~/requests/user';
import { Table } from '~/components';
import sendSettingsClickEvent from '~/utils/analytics/sendSettingsClickEvent';

interface NotificationSettingsCheckboxProps {
  name: string;
  code: string;
  group: 'developer' | 'investor';
  category: 'LEAD' | 'MEMBER' | 'NON_MEMBER';
  checked: boolean;
  disabled?: boolean;
}

const NotificationSettingCheckbox: React.FC<NotificationSettingsCheckboxProps> = (props) => {
  const { name, group, code, category, checked, disabled } = props;
  const [checkedState, setCheckedState] = useState(checked);
  const queryClient = useQueryClient();

  const { mutateAsync, status } = useMutation((flag: boolean) => {
    return updateNotificationPreferences({
      action: flag ? 'add' : 'delete',
      group,
      preferences: { code, category }
    }).catch(e => {
      console.error(e);
      toast.error('Failed to update notification settings. Please try again.');
      throw e;
    });
  });

  const toggleCheckedState = async () => {
    // Analytics
    sendSettingsClickEvent('Default Notifications Changed');
    
    const newCheckedState = !checkedState;
    try {
      setCheckedState(newCheckedState);
      const result = await mutateAsync(newCheckedState);
      queryClient.setQueryData(notificationPreferencesQueryKey(), result);
    } catch (e: any) {
      console.error(e);
      setCheckedState(!newCheckedState);
    }
  };

  const isDisabled = disabled || status === 'loading';

  return (
    <Table.CellCheckbox 
      ariaLabel={`Notification setting for ${name} - ${CategoryNameMap[category]}`}
      checked={checkedState}
      onChange={toggleCheckedState}
      disabled={isDisabled} 
    />
  );
};

export default NotificationSettingCheckbox;
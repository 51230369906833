import { Button } from '~/components';
import Modal from '~/components/Modal';
import { usePortfolioContext } from '~/hooks';
import { useMutation } from 'react-query';
import { withPromiseToaster } from '~/utils';
import { removePortfolioProject } from '~/api/portfolioProjects';
import { useHistory } from 'react-router-dom';
import { Project } from '~/types/project';
import { useQueryClient } from 'react-query';
import { useProjectList, getListProjectsKey } from '~/requests/projects/useProjectList';
import { DealStates } from '~/constants';

// This modal cannot be opened using the openModal() function -- it must be opened within a portfolio context

interface RemovePortfolioProjectModalProps {
  project: Project;
  closeModal: () => void;
  onRemove: () => void;
}

export default function RemovePortfolioProjectModal({ 
  project, 
  closeModal,
  onRemove
}: RemovePortfolioProjectModalProps) {
  const history = useHistory();
  const projectId = project.id;
  const { 
    id: portfolioId,
    portfolio, 
    projectBlobs,
    portfolioState,
    fetchPortfolio, 
    updatePortfolioValue 
  } = usePortfolioContext();
  const queryClient = useQueryClient();
  const { data: projectListQueryResult } = useProjectList();
  const parentProjectBlobs = projectBlobs?.filter((projectBlob: any) => !projectBlob?.project?.parentId);

  const { mutate: removeProject, status: removeProjectStatus } = useMutation(
    async () => {
      await withPromiseToaster(
        removePortfolioProject(portfolioId, projectId)
          .then((projects) => {
            if (projects.revertStandaloneProject) {
              // This was converted to a standalone project. Redirect accordingly
              history.push(`/project/${projects.revertStandaloneProject.id}`);
            } else if (portfolio.investorId && projects.map((project: Project) => project.investorId).filter((investorId: string) => !!investorId).length === 0) {
              fetchPortfolio();
            } else {
              // If there's no error, update the portfolio in the cache
              updatePortfolioValue('projects', projects, { doSave: false });

              // Update project portfolioId in projectList query
              const updatedProjectList = projectListQueryResult.map((project: Project) => {
                if (project.id === projectId) {
                  return { ...project, portfolioId: null, parentPortfolioId: null };
                } else {
                  return project;
                }
              });
          
              queryClient.setQueryData(getListProjectsKey(), updatedProjectList);
            }
          }).catch((err: any) => {
            // If there's an error, re-select the checkbox and throw the error
            console.error(err);
            throw err;
          }),
        {
          messageStub: 'removing the project from your portfolio'
        }
      );
    }
  );

  return (
    <Modal closeModal={() => removeProjectStatus !== 'loading' && closeModal()}>
      <Modal.Title>
      {parentProjectBlobs.length === 1
          ? 'Confirm deletion'
          : 'Confirm removal from portfolio'}
      </Modal.Title>
      <Modal.Content>
        <p>
          Are you sure you want to remove the <strong>{project.name}</strong> project from the <strong>{portfolio.name}</strong> portfolio?
        </p>
        {parentProjectBlobs.length === 2 && portfolioState.earliestState >= DealStates.IN_REVIEW && (
          <p style={{ fontWeight: '700' }}>
            Since only one project remains, this portfolio will be removed and the project will be a standalone project.
          </p>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button 
          primary 
          disabled={removeProjectStatus === 'loading'} 
          loading={removeProjectStatus === 'loading'} 
          onClick={async () => {
            await removeProject();
            onRemove();
            closeModal();
          }}
        >
          Confirm
        </Button>
        <Button 
          outlined 
          disabled={removeProjectStatus === 'loading'} 
          primary 
          onClick={closeModal}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
import { getAdminNotes } from '~/api/admin';
import { useQuery } from 'react-query';

export const getAdminNotesKey = (projectId: any) => {
  return projectId;
};

export const useAdminNotes = (projectId: any, queryParams = {}) => {
  return useQuery({
    queryKey: getAdminNotesKey(projectId) as any,
    queryFn() {
      if (projectId) {
        return getAdminNotes(projectId);
      } else {
        return { notes: '' };
      }
    },

    // Cache for 5 minutes
    staleTime: 60 * 1000 * 5, 

    // Don't automatically fetch the file.
    // This is meant to be called as-needed in response to a
    // button click via the "download" prop returned by this hook.
    enabled: true,

    // No retries
    retry: false,

    // Allow overriding default react-query props
    ...queryParams
  });
};
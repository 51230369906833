import { createContext, useContext, useState, useEffect } from 'react';
import { ProjectStatus, TutorialCodes, DealStates } from '~/constants';
import { useUserContext } from '~/hooks/index';
import { useModalContext } from './ModalProvider';
import { useTutorialList } from '~/requests/tutorials/useTutorialList';
import DevEarlyDataroomWelcome from '~/features/tutorials/components/modals/developers/DevEarlyDataroomWelcome';
import DevV2Update from '~/features/tutorials/components/modals/developers/DevV2Update';
import FirstDevBid from '~/features/tutorials/components/modals/developers/FirstDevBid';
import FirstDevInvestorInvited from '~/features/tutorials/components/modals/developers/FirstDevInvestorInvited';
import FirstDevLogin from '~/features/tutorials/components/modals/developers/FirstDevLogin';
import FirstDevProjectCreated from '~/features/tutorials/components/modals/developers/FirstDevProjectCreated';
import FirstDevProjectSubmission from '~/features/tutorials/components/modals/developers/FirstDevProjectSubmission';
import FirstInvLogin from '~/features/tutorials/components/modals/investors/FirstInvLogin';
import FirstInvProjectMatch from '~/features/tutorials/components/modals/investors/FirstInvProjectMatch';
import InvDataroomSetup from '~/features/tutorials/components/modals/investors/InvDataroomSetup';
import InvEarlyDataroomWelcome from '~/features/tutorials/components/modals/investors/InvEarlyDataroomWelcome';
import InvV2Update from '~/features/tutorials/components/modals/investors/InvV2Update';
import { Project } from '~/types/project';

const TutorialContext = createContext({});

const TutorialProvider = ({ children }: any) => {
  const { loggedInUser } = useUserContext() as any;
  const { openModal } = useModalContext();
  const { data: tutorials } = useTutorialList();

  // Use fetch tutorials response and complete tutorials responses for determining the tutorials list
  const hasTutorial = (code: any) => {
    return !!tutorials?.find((tutorial: any) => tutorial.code === code);
  };

  // Function for setting and storing data
  const [tutorialContext, setTutorialContext] = useState(null);

  // useEffect that runs with tutorialContext and tutorials list changes
  useEffect(() => {
    const { page, data } = tutorialContext ?? {} as any;
    const tutorialQueue: any = [];
    const showingTutorial = (code: any) => tutorialQueue[0] === code;

    // Prioritize V2 update message above others
    if (hasTutorial(TutorialCodes.V2_UPDATE_MESSAGE)) {
      tutorialQueue.push(TutorialCodes.V2_UPDATE_MESSAGE);
    }

    // Tutorials for dashboard
    if (page === 'dashboard') {

      if (!data.projects?.length && hasTutorial(TutorialCodes.FIRST_LOGIN)) {
        tutorialQueue.push(TutorialCodes.FIRST_LOGIN);
      }
      if (data.projects?.length >= 2 && hasTutorial(TutorialCodes.FIRST_PROJECT_CREATED)) {
        tutorialQueue.push(TutorialCodes.FIRST_PROJECT_CREATED);
      }
      if (data.projects?.length && hasTutorial(TutorialCodes.FIRST_PROJECT_MATCH)) {
        tutorialQueue.push(TutorialCodes.FIRST_PROJECT_MATCH);
      }
      if (data.projects?.find((p: Project) => p.status === ProjectStatus.DRAFT && p.worksheetValid) && hasTutorial(TutorialCodes.PROJECT_SUBMISSION_READY)) {
        tutorialQueue.push(TutorialCodes.PROJECT_SUBMISSION_READY);
      }

      const hasBiddingInvestor = data.projects?.some((p: Project) => p.statusDisplay === 'BIDDING');
      if (hasBiddingInvestor && hasTutorial(TutorialCodes.FIRST_INVESTOR_INVITED)) {
        tutorialQueue.push(TutorialCodes.FIRST_INVESTOR_INVITED);
      }

      // First developer login tutorial
      if (loggedInUser.isInstaller && showingTutorial(TutorialCodes.FIRST_LOGIN)) {
        openModal(FirstDevLogin, data);
      }

      // First investor login tutorial
      if (loggedInUser.isInvestor && showingTutorial(TutorialCodes.FIRST_LOGIN)) {
        openModal(FirstInvLogin, data);
      }

      // First project created tutorial
      if (showingTutorial(TutorialCodes.FIRST_PROJECT_CREATED)) {
        openModal(FirstDevProjectCreated, data);
      }

      // Project submission ready modal
      if (showingTutorial(TutorialCodes.PROJECT_SUBMISSION_READY)) {
        openModal(FirstDevProjectSubmission, data);
      }

      // First project match tutorial
      if (showingTutorial(TutorialCodes.FIRST_PROJECT_MATCH)) {
        openModal(FirstInvProjectMatch, data);
      }

      // First investor invited tutorial
      if (showingTutorial(TutorialCodes.FIRST_INVESTOR_INVITED)) {
        openModal(FirstDevInvestorInvited, data);
      }
    }

    //V2 tutorial modals
    if (loggedInUser.isInstaller && showingTutorial(TutorialCodes.V2_UPDATE_MESSAGE)) {
      openModal(DevV2Update, data);
    }

    if (loggedInUser.isInvestor && showingTutorial(TutorialCodes.V2_UPDATE_MESSAGE)) {
      openModal(InvV2Update, data);
    }

    // Tutorials for dataroom
    if (page === 'dataroom') {

      if (hasTutorial(TutorialCodes.EARLY_DATAROOM_LANDING_WELCOME)) {
        tutorialQueue.push(TutorialCodes.EARLY_DATAROOM_LANDING_WELCOME);
      }

      if ((data.project?.dealState >= DealStates.IN_DILIGENCE) && hasTutorial(TutorialCodes.DATA_ROOM_SETUP)) {
        tutorialQueue.push(TutorialCodes.DATA_ROOM_SETUP);
      }

      //Developer early dataroom welcome tutorial
      if (showingTutorial(TutorialCodes.EARLY_DATAROOM_LANDING_WELCOME)) {
        openModal(DevEarlyDataroomWelcome, data);
      }

      //Investor dataroom setup tutorial
      if (showingTutorial(TutorialCodes.DATA_ROOM_SETUP)) {
        openModal(InvDataroomSetup, data);
      }
    }

    // Tutorials for project summary
    if (page === 'projectSummary') {

      if (data.permissions?.canReadDataroom && data.projectState?.dealState < DealStates.IN_DILIGENCE && hasTutorial(TutorialCodes.INVESTOR_EARLY_DATAROOM_WELCOME)) {
        tutorialQueue.push(TutorialCodes.INVESTOR_EARLY_DATAROOM_WELCOME);
      }

      if (data.projectState?.dealState === DealStates.PENDING_BID && hasTutorial(TutorialCodes.BIDS_RECEIVED)) {
        tutorialQueue.push(TutorialCodes.BIDS_RECEIVED);
      }

      // Investor early dataroom welcome tutorial
      if (showingTutorial(TutorialCodes.INVESTOR_EARLY_DATAROOM_WELCOME)) {
        openModal(InvEarlyDataroomWelcome, data);
      }

      // First developer bid received tutorial
      if (showingTutorial(TutorialCodes.BIDS_RECEIVED)) {
      openModal(FirstDevBid, data);
      }
    }
 
  }, [tutorialContext, tutorials, loggedInUser]);

  return (
    <TutorialContext.Provider value={{ setTutorialContext }}>
      {children}
    </TutorialContext.Provider>
  );
};

export default TutorialProvider;

export const useTutorials = () => useContext(TutorialContext);
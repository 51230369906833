import TutorialModal from '../../TutorialModal';
import { TutorialCodes } from '~/constants';

export default function FirstDevBid() {

  return (
    <TutorialModal
      title='Managing bids'
      content={
        <>
          <p>
            You’ve received your first bid on a project!
          </p>
          <p>
            As more bids come in, they’ll be viewable alongside this one.
          </p>
        </>
      }
      hasCancelButton={false}
      submitButtonText='OK'
      tutorialCode={TutorialCodes.BIDS_RECEIVED} 
    />  
  );
};


import { Project } from '~/types/project';
import * as S from './styles';
import { usePortfolioContext } from '~/hooks';
import { getProjectBidOverallAmount } from '~/utils/calculators/costs';
import { PricingType, UnitDecimals } from '~/constants';
import { sizeKwdc } from '~/utils/calculators';
import { formatDollars } from '~/utils';

export default function ProjectTotals() {
  const { portfolioDisplayValues, projectBlobs } = usePortfolioContext();
  const parentProjectBlobs = projectBlobs?.filter((projectBlob: any) => !projectBlob?.project?.parentId) ?? [];
  const investors = portfolioDisplayValues?.dealSummary?.investors;
  const investorProjectBidMap = portfolioDisplayValues?.dealSummary?.investorProjectBidMap;
  const hasPendingBid = Object.values(investorProjectBidMap).some((row: any) => Object.values(row).some((row: any) => row.pendingBid));

  // Only show if the pricing type is EPC + Dev Fee
  if (!hasPendingBid || projectBlobs.some((projectBlob: any) => projectBlob?.project?.pricingType !== PricingType.ACQUISITION)) {
    return null;
  }

  return (
    <>
      <S.DealSummaryLabel style={{ borderTop: '1px solid var(--color-charcoal) !important'}}>
        <div style={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          Portfolio total
        </div>
      </S.DealSummaryLabel>
      {investors.map((investor: any, i: number) => {
        const investorProjectData = investorProjectBidMap?.[investor?.id];

        const bidProjectIds = Object.values(investorProjectData)
          .filter((row: any) => row.pendingBid)
          .map((row: any) => row.projectId) as string[];

        const bidProjectBlobs = projectBlobs
          ?.filter((projectBlob: any) => bidProjectIds.includes(projectBlob?.project?.id))
          ?? [];

        const total = Math.round(bidProjectBlobs.reduce((acc: number, projectBlob: any) => {
          const project = projectBlob?.project as Project;
          const bid = projectBlob?.projectCosts?.bid as any;
          if (project && bid) {
            acc += getProjectBidOverallAmount(project, bid, false);
          }
          return acc;
        }, 0));

        const totalSizeKwdc = bidProjectBlobs.reduce((acc: number, projectBlob: any) => {
          acc += sizeKwdc(projectBlob.project);
          return acc;
        }, 0);
        const totalPerWdc = total && totalSizeKwdc ? total / totalSizeKwdc / 1000 : 0;

        return (
          <div 
            key={investor?.id} 
            style={{ 
              borderTop: '1px solid var(--color-charcoal)', 
              fontSize: '0.875rem',
              padding: '0.5rem 0.25rem',
              ...(i === investors.length - 1 ? { borderRight: '1px solid var(--color-grey)' } : {})
            }}
          >
            {total ? (
              <S.DealSummaryCell style={{ border: 'none' }}>
                <div style={{ color: 'var(--color-pure-black)', fontWeight: 'bold' }}>{formatDollars(total, { dec: 0 })} / {formatDollars(totalPerWdc, { dec: UnitDecimals.WDC})} per Wdc</div>
                <div style={{ color: 'var(--color-charcoal)', fontWeight: 'bold' }}>{bidProjectIds?.length ?? 0} / {parentProjectBlobs?.length ?? 0} projects bid</div>
              </S.DealSummaryCell>
            ) : null}
          </div>
        );
      })}
    </>
  );
}
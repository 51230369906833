import { ReactNode, createContext, useCallback, useContext, useEffect } from 'react';
import { ProjectWizardConfig, ProjectWizardConfigMap } from '../constants';
import { useQueryParamState } from '~/hooks/useQueryParamState';
import { cloneDeep } from 'lodash';
import { useProjectContext } from '~/hooks';
import useAnalyticsPageView from '~/hooks/analytics/useAnalyticsPageView';

type ProjectWizardScreen = { sectionId: string; subsectionId: string }
interface ProjectWizardContextParams {
  currentScreen: ProjectWizardScreen
  selectScreen: (sectionId: string, subsectionId?: string) => void
  getNextScreen: () => 
    {
      submitButtonLabel: string;
      isFormSubmit: boolean;
      sectionId?: undefined;
      subsectionId?: undefined;
    } | {
      submitButtonLabel: string;
      sectionId: string;
      subsectionId: string;
      isFormSubmit?: undefined;
    } | 
    undefined
  isScreenSelected: (sectionId: string, subsectionId: string) => boolean
  config: typeof ProjectWizardConfig
}

const ProjectWizardContext = createContext({} as ProjectWizardContextParams);

interface ProjectWizardProviderProps {
  children?: ReactNode
}

const ProjectWizardProvider = ({ children }: ProjectWizardProviderProps) => {
  const [currentScreen, setCurrentScreen] = useQueryParamState('s', { historyBehavior: 'push' });
  const {
    conditions: {
      sectionLabel,
      subsectionLabel,
      isSectionDisplaying,
      isSubsectionDisplaying
    }
  } = useProjectContext();
  const sections = cloneDeep(ProjectWizardConfig).filter(section => isSectionDisplaying(section.id));
  sections.forEach(section => {
    section.subsections = section.subsections.filter(subsection => isSubsectionDisplaying(subsection.id));
  });

  // Analytics
  useAnalyticsPageView('/wizard', 'Wizard');

  useEffect(() => {
    const section = sections.find(section => section.id === currentScreen?.sectionId) ?? sections[0];
    const subsection = section.subsections
      .find(subsection => subsection.id === currentScreen?.subsectionId) 
    ?? section.subsections[0];
    
    setCurrentScreen({
      sectionId: section.id,
      subsectionId: subsection.id
    });
  }, []);

  const selectScreen = useCallback((sectionId: string, subsectionId?: string) => {
    const section = sections.find(item => item.id === sectionId);

    // Set default section if sectionId is invalid
    if (!section) {
      setCurrentScreen({
        sectionId: sections[0].id,
        subsectionId: sections[0].subsections[0].id
      });
      return;
    }
    
    // Set default subjection if subsectionId is invalid
    if (!subsectionId || !section.subsections.find(subsection => subsection.id === subsectionId)) {
      subsectionId = section.subsections[0].id;
    }
    setCurrentScreen({ sectionId, subsectionId });
  }, [sections]);

  const isScreenSelected = useCallback((sectionId: string, subsectionId: string) => {
    return currentScreen.sectionId === sectionId && currentScreen.subsectionId === subsectionId;
  }, [currentScreen]);

  const lowercaseLabel = useCallback((label: string) => {
    if (label && label !== 'O&M') {
      return label.charAt(0).toLowerCase() + label.slice(1);
    }

    return label;
  }, []);

  const getNextScreen = useCallback(() => {
    if (currentScreen?.sectionId && currentScreen?.subsectionId) {
      const sectionIdx = sections.findIndex(section => section.id === currentScreen.sectionId);
      const section = sections[sectionIdx];
      const subsections = section.subsections;
      const subsectionIdx = subsections.findIndex(subsection => subsection.id === currentScreen.subsectionId);
      const isLastSection = sectionIdx >= sections.length - 1;
      const isLastSubsection = subsectionIdx >= subsections.length - 1;
      if (isLastSection && isLastSubsection) {
        return {
          submitButtonLabel: 'Proceed to project summary',
          isFormSubmit: true
        };
      } else if (isLastSubsection) {
        const nextSection = sections[sectionIdx + 1];
        return {
          submitButtonLabel: `Proceed to ${lowercaseLabel(sectionLabel(nextSection.id, nextSection.label))}`,
          sectionId: nextSection.id,
          subsectionId: nextSection.subsections[0].id
        };
      } else {
        const nextSubsection = section.subsections[subsectionIdx + 1];
        const submitButtonLabel = nextSubsection?.submitButtonText ?? lowercaseLabel(subsectionLabel(nextSubsection.id, nextSubsection.label));
        
        return {
          submitButtonLabel: `Proceed to ${submitButtonLabel}`,
          sectionId: section.id,
          subsectionId: nextSubsection.id
        };
      }
    }
  }, [sections, currentScreen]);
  
  return (
    <ProjectWizardContext.Provider value={{ config: sections, currentScreen, isScreenSelected, selectScreen, getNextScreen }}>
      {ProjectWizardConfigMap[currentScreen?.sectionId]?.subsections?.[currentScreen?.subsectionId]
        ? children 
        : null}
    </ProjectWizardContext.Provider>
  );
};

export default ProjectWizardProvider;

export const useProjectWizardContext = () => useContext(ProjectWizardContext);

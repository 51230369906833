import * as S from '../../../../components/GridComponent';
import { colors } from '~/theme';
import { formatDollars } from '~/utils';
import { display } from '~/utils/displayValues/common';
import { UnitDecimals } from '~/constants';

interface BudgetTableProps {
  values: any;
  format: 'responsive' | 'fixed';
  pricingType?: 'ACQUISITION' | 'OFFTAKE' | 'ITC' | 'DEV_FEE' | 'BRIDGE_LOAN'; 
  hasDividerLine?: boolean; 
  hasProjectTotals?: boolean; 
  bidAmount?: number; 
}

export default function BudgetTable({ 
  values, 
  format,
  pricingType, 
  hasDividerLine,
  hasProjectTotals,
  bidAmount, 
}: BudgetTableProps) {
  
  const budget = values?.type === 'budget' ? values?.data : values?.budget; 

  const epcCost = budget?.epcCost?.perWdc;
  const epcCostTotal = budget?.epcCost?.total;
  const interconnCost = budget?.interconnection?.perWdc;
  const interconnTotal = budget?.interconnection?.total;
  const otherItcCost = budget?.otherItc?.perWdc;
  const otherItcTotal = budget?.otherItc?.total;
  const otherNonItcCost = budget?.otherNonItc?.perWdc;
  const otherNonItcTotal = budget?.otherNonItc?.total;
  const conductorFee = budget?.conductorSolarFee?.perWdc;
  const conductorFeeTotal = budget?.conductorSolarFee?.total;
  const totalPerWdc = budget?.totals?.perWdc;
  const total = budget?.totals?.total;

  const displayConductorFee = budget?.conductorSolarFee?.isDisplaying;
  const isHighlighted = budget?.epcCost?.isHighlighted;

  const bidOfftake = display(bidAmount && formatDollars(bidAmount, { dec: UnitDecimals.KWH, default0: 'TBD' })); 

  const columnValues = format === 'responsive' ? '1.5fr 1fr 1fr' : format === 'fixed' ? '11rem 6rem 6rem' : null;

  return (
    <S.GridContainer>

      <S.SubGrid 
        columnValues={columnValues}
        gridColumn='1 / 4' 
        style={{paddingBottom: 'var(--small)'}}
      >
        <div className="title">Value</div>
        <strong>$ per Wdc</strong>
        <strong>Total amount</strong>
      </S.SubGrid> 

      <S.GridTable 
        columnValues={columnValues}
        gridColumn='1 / 4'
      >
        <S.GridLabelEl style={{color: pricingType === 'ACQUISITION' ? colors.orange : colors.charcoal}}> 
          {pricingType === 'ACQUISITION' ? 'EPC cost + dev fee - your bid' : 'EPC cost + dev fee'}
        </S.GridLabelEl>
        <S.GridTableEl 
          gridColumn='2 / 3' 
          textAlign='right'
          style={{ 
            color: isHighlighted || pricingType === 'ACQUISITION' ? colors.orange : colors.pureBlack, 
            fontWeight: isHighlighted || pricingType === 'ACQUISITION' ? 'bold' : 'normal'
          }} 
        >
          {epcCost}
        </S.GridTableEl>
        <S.GridTableEl 
          gridColumn='3 / 4' 
          textAlign='right'
          style={{ 
            color: isHighlighted || pricingType === 'ACQUISITION' ? colors.orange : colors.pureBlack, 
            fontWeight: isHighlighted || pricingType === 'ACQUISITION' ? 'bold' : 'normal'
          }}
        >
          {epcCostTotal}
        </S.GridTableEl>

        <S.GridLabelEl>Interconnection</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 3' textAlign='right'>{interconnCost}</S.GridTableEl>
        <S.GridTableEl gridColumn='3 / 4' textAlign='right'>{interconnTotal}</S.GridTableEl>

        <S.GridLabelEl>Other ITC-eligible costs</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 3' textAlign='right'>{otherItcCost}</S.GridTableEl>
        <S.GridTableEl gridColumn='3 / 4' textAlign='right'>{otherItcTotal}</S.GridTableEl>
        
        <S.GridLabelEl>Non-ITC eligible costs</S.GridLabelEl>
        <S.GridTableEl gridColumn='2 / 3' textAlign='right'>{otherNonItcCost}</S.GridTableEl>
        <S.GridTableEl gridColumn='3 / 4' textAlign='right'>{otherNonItcTotal}</S.GridTableEl>

        {displayConductorFee ? (
        <>   
          <S.GridLabelEl>Conductor Solar fee</S.GridLabelEl>
          <S.GridTableEl gridColumn='2 / 3' textAlign='right'>{conductorFee}</S.GridTableEl>
          <S.GridTableEl gridColumn='3 / 4' textAlign='right'>{conductorFeeTotal}</S.GridTableEl>
        </>
        ) : null}

        {hasDividerLine && (
          <S.GridLabelEl 
          gridColumn='1 / 4'
          style={{
            padding: '0',
            marginBottom: 'var(--x-small)',
            borderBottom: '1px solid var(--color-charcoal)'
          }} />
        )}

        <S.GridLabelEl
          gridColumn='1 / 2' 
          className="total" 
        >
          {hasProjectTotals ? 'Project Totals' : 'Total cost to you'}
        </S.GridLabelEl>
        <S.GridTableEl 
          gridColumn='2 / 3' 
          textAlign='right'
          className="total"
        >
          <strong>{totalPerWdc}</strong>
        </S.GridTableEl>
        <S.GridTableEl 
          gridColumn='3 / 4' 
          textAlign='right'
          className="total"
        >
          <strong>{total}</strong>
        </S.GridTableEl>

        {pricingType === 'OFFTAKE' && (  
          <>
            <S.GridLabelEl
              gridColumn='1 / 2'
              style={{
                color: 'var(--color-primary)', 
                fontWeight: '700',
                marginTop: 'var(--small)'
              }}
            >
              Offtake rate - your bid
            </S.GridLabelEl>

            <S.GridTableEl 
              gridColumn='2 / 3' 
              textAlign='right'
              style={{
                color: 'var(--color-primary)', 
                fontWeight: '700',
                marginTop: 'var(--small)'
              }}
            >
            {`${bidOfftake} / kWh`} 
            </S.GridTableEl>
          </>
        )}
      </S.GridTable>
    </S.GridContainer>
  );
};
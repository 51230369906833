import { QueryClient, QueryClientProvider } from 'react-query';
import UserContextProvider from '~/hooks/providers/UserContextProvider';
import LoginForm from '~/features/user/login/LoginForm';
import ForgotPasswordForm from '~/features/user/login/ForgotPasswordForm';
import ResetPasswordForm from '~/features/user/login/ResetPasswordForm';
import UserSignup from '~/features/user/signup/UserSignup';
import Acknowledgements from '~/routes/Acknowledgements';
import Dashboard from '~/features/dashboard';
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import AuthRoute from '~/components/AuthRoute';
import Portfolio from '~/routes/Portfolio';
import Project from '~/routes/Project';
import InviteSignup from '~/routes/InviteSignup';
import { UserRole } from '~/constants';
import { isQaEnvironment, storage } from './utils';
import BreadcrumbsContextProvider from '~/hooks/providers/BreadcrumbsContextProvider';
import { useEffect, useRef } from 'react';
import ClientLibrary from '~/features/ClientLibrary';
import AdminUsers from './features/admin/users';
import UserSettings from './features/user/settings';
import ReactModal from 'react-modal';
import AppTitle from './components/AppTitle';
import NotificationResolver from './routes/NotificationResolver';
import QA from './routes/QA';
import AdminCompanies from './features/admin/companies';
import EmailVerification from './features/user/emailVerification/EmailVerification';
import RunEmailVerification from './features/user/emailVerification/RunEmailVerification';
import useLandingToast from './hooks/useLandingToasts';
import Logout from './features/user/logout/Logout';
import initializeAnalytics from './utils/analytics/initializeAnalytics';

initializeAnalytics();

function ScrollToTopOnRouteChange() {
  const history = useHistory();
  const currentRouteRef = useRef(history.location);

  const isSame = (path1, path2) => {
    return path1 === path2 || (
      // This is done because of how budget/comments tabs are distinct
      // routes for portfolios
      // TODO update these tabs to be query params similar to the project review page
      (path1?.endsWith('/budget') && path2?.endsWith('/comments')) || 
      (path1?.endsWith('/comments') && path2?.endsWith('/budget'))
    );
  };

  useEffect(() => {
    const unlisten = history.listen((route) => {
      if (!isSame(currentRouteRef?.current?.pathname, route?.pathname)) {
        window.scrollTo(0, 0);
      }
      currentRouteRef.current = route;
    });
    return () => {
      unlisten();
    };
  }, []);

  return (null);
}

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AppRouter />
    </QueryClientProvider>
  );
}

function AppRouter() {
  useLandingToast();

  const loggedIn = !!storage.getStoredCurrentUser();
  ReactModal.setAppElement('body');

  const isQA = isQaEnvironment();

  return (
    <div className="app-content">
      <UserContextProvider>
        <BreadcrumbsContextProvider>
          <AppTitle />
          <Router>
            <ScrollToTopOnRouteChange />
            <Switch>

              {/* redirects */}
              <Redirect exact from="/" to="/dashboard"/>
              <Redirect exact from="/signup" to="/dashboard"/>
              <Redirect exact from="/worksheet/:id" to="/project/:id/worksheet"/>
              <Redirect exact from="/worksheet/:id/:section" to="/project/:id/worksheet/:section"/>
              <Redirect exact from="/dataroom/:id" to="/project/:id/dataroom"/>
              <Redirect exact from="/dataroom/:id/doclist" to="/project/:id/dataroom/doclist"/>                

              {/* public routes */}
              <Route path="/login">{loggedIn ? <Redirect to={(new URLSearchParams(window.location.search))?.get('redirect_uri') || '/'}/> : <LoginForm/>}</Route>
              <Route path="/logout"><Logout /></Route>
              <AuthRoute path="/confirm-email" exact>
                <EmailVerification />
              </AuthRoute>
              <AuthRoute path="/confirm-email/:token" exact>
                <RunEmailVerification />
              </AuthRoute>
              <Route path="/signup-investors">
                <UserSignup userType={UserRole.INVESTOR}/>
              </Route>
              <Route path="/signup-developer-EPC">
                <UserSignup userType={UserRole.INSTALLER}/>
              </Route>
              <Route path="/team/invite/:token">
                <InviteSignup/>
              </Route>
              <Route path="/forgot-password">
                <ForgotPasswordForm/>
              </Route>
              <Route path="/reset-password/:token">
                <ResetPasswordForm/>
              </Route>

              {/* authenticated routes */}
              <AuthRoute path="/acknowledgements">
                <Acknowledgements/>
              </AuthRoute>
              <AuthRoute path="/settings">
                <UserSettings />
              </AuthRoute>
              <AuthRoute path="/users">
                <AdminUsers />
              </AuthRoute>
              <AuthRoute path="/companies">
                <AdminCompanies />
              </AuthRoute>
              <AuthRoute path="/dashboard">
                <Dashboard/>
              </AuthRoute>
              <AuthRoute path="/project/:id">
                <Project/>
              </AuthRoute>
              <AuthRoute path="/portfolio/:id">
                <Portfolio/>
              </AuthRoute>
              <AuthRoute path="/notification/:id">
                <NotificationResolver />
              </AuthRoute>

              {isQA && (
                <AuthRoute path="/qa">
                  <QA />
                </AuthRoute>
              )}

              {/* client libary */}
              <AuthRoute path="/client-library">
                <ClientLibrary />
              </AuthRoute>

            </Switch>
          </Router>
        </BreadcrumbsContextProvider>
      </UserContextProvider>
    </div>
  );
}

export default App;

import { TutorialCodes } from '~/constants';
import { useModalContext } from '~/hooks/providers/ModalProvider';
import CreateProjectModal from '~/modals/CreateProjectModal';
import TutorialModal from '../../TutorialModal';
import Link from '~/components/Link';

export default function FirstDevLogin() {
  const { openModal } = useModalContext();

  return (
    <TutorialModal
      title='Getting started on Conductor Solar'
      content={
        <>
          <p>
            Welcome to Conductor! We can’t wait to get your projects matched with investment partners!
            To get started, click the box below to set up your first project.
            Take your time to navigate through the data entry screens and record project details.
          </p>
          <p>
            If you get stuck or want to talk, feel free to contact our customer service team at{' '}
            <Link bold underline as='a' href="mailto:support@conductor.solar">support@conductor.solar</Link>, or{' '}
            <Link bold underline as='a' href="https://calendly.com/conductorsolar/conductor-solar-projects-to-finance?month=2023-12" rel="noopener noreferrer" target="_blank">
              click here
            </Link>{' '}
            to book a support session.
          </p>
        </>
      }
      hasCancelButton
      cancelText='OK'
      submitButtonText='Create a project'
      tutorialCode={TutorialCodes.FIRST_LOGIN}
      onSubmit={() => {openModal(CreateProjectModal);}}
    />  
  );
};  

